import React from 'react'

const withContainer = Component => ({ type, ...props }) => {

  const mergedProps = {
    type: type === 1 ? 'driver' : 'vehicle'
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
