
import React from 'react'
import { I18n } from '@front/volcanion'

import { Grid, Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import PrimarySection from '@abra/pages/Pricerule/Details/Section/Primary'
import ScheduleSection from '@abra/pages/Pricerule/Details/Section/Schedule'
import GeoSection from '@abra/pages/Pricerule/Details/Section/Geo'
import CeilingSection from './Section/Ceiling'

const Ceiling = ({ company_id, companycontract_id, companyContractLabel }) =>
  <Grid container justifyContent='center'>
    <Grid item xs={8}>
      <Grid container rowSpacing={1}>
        <Grid item xs={12} >
          <FormHeader
            subTitle={I18n.t('link.ceiling')}
            onLinkClick={() => window.open(`/company/${company_id}/contract/${companycontract_id}`, '_blank')}
            linkText={companyContractLabel}
          />
        </Grid>
        <Grid item xs={12} >
          <GridStepper groupsCount={[4]} activeStep={0} orientation="vertical">
            <Step>
              <PrimarySection />
            </Step>
            <Step>
              <ScheduleSection />
            </Step>
            <Step>
              <GeoSection isCeiling={true} />
            </Step>
            <Step>
              <CeilingSection />
            </Step>
          </GridStepper>
        </Grid>
      </Grid>
    </Grid>
  </Grid >

export default React.memo(Ceiling)
