import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Grid, Box } from '@mui/material'

import Callbacks from './callbacks'

import CompanyTab from '@abra/panels/Tab/Company'
import Form from './Form'
import Header from './Header'
import Row from './Row'

const CompanyList = props => {
  const navigate = hooks.useNavigate()
  const { company_id } = hooks.useParams()

  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const filterToState = useCallback(Callbacks.filterToStateHandler(company_id), [company_id])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])
  const handleCreate = useCallback(() => navigate(`/company/${company_id}/contract/create`), [navigate, company_id])
  const onRowClick = useCallback((id, row) => navigate(`/company/${_.get(row, 'company')}/contract/${_.get(row, 'companycontract_id')}`), [navigate])

  return <Box className='safearea'>
    <Grid container spacing={1} justifyContent='center' rowSpacing={1.5}>
      <Grid item xs={12}>
        {!!company_id && <CompanyTab isManagingRecord={true} value={2} />}
      </Grid>
      <Grid item xs={12} sm={10} lg={10}>
        <SearchFrame
          model_name={'companycontract'}
          populate={['contact', 'formula']}
          allowCreate={!!company_id}
          createButtonProps={{
            label: I18n.t('contract.add'),
            onClick: handleCreate
          }}
        >
          <SearchForm
            submitOnMount
            filterToForm={filterToForm}
            filterToState={filterToState}
            formToFilter={formToFilter}
          >
            <FormContent><Form company_id={company_id} /></FormContent>
          </SearchForm>
          <SearchTable tableProps={{ onRowClick }}>
            <TableHeader><Header /></TableHeader>
            <TableRow><Row /></TableRow>
          </SearchTable>
        </SearchFrame>
      </Grid>
    </Grid >
  </Box>

}

export default React.memo(CompanyList)
