import FormatUtils from '@front/squirtle/utils/format'
class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static filterToStateHandler(company) {
    return function filterToState(state) {
      return { company }
    }
  }
  static formToFilterHandler() {
    return function formToFilter({ operator, createdAt_start, createdAt_end, ...values }, extra, meta, state) {
      const name = _.get(operator, 'name')

      return {
        createdAt: FormatUtils.intervalFilter(createdAt_start, createdAt_end),
        operator: !!name ? {
          info: { or: [{ last_name: name }, { first_name: name }] }
        } : undefined,
        ...values
      }
    }
  }
}

export default Callbacks
