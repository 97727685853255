import FormatUtils from '@front/squirtle/utils/format'

class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      const { createdAt_start, createdAt_end, driver, vehicle } = values
      const createdAt = FormatUtils.intervalFilter(createdAt_start, createdAt_end)
      return {
        createdAt,
        driver: _.has(values, 'driver') ? driver : undefined,
        vehicle: _.has(values, 'vehicle') ? vehicle : undefined
      }
    }
  }
  static afterSubmitHandler(getDailyLocations) {
    return function afterSubmit(result, values, extra, meta, state) {
      const { driver, vehicle, createdAt_start, createdAt_end } = values
      const createdAt = FormatUtils.intervalFilter(createdAt_start, createdAt_end)
      getDailyLocations({ vehicle, driver, createdAt })
    }
  }
}

export default Callbacks
