import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => ({ company_id, ...props }) => {
  const [company] = hooks.useModel('company', [company_id], { single: true })

  const mergedProps = {
    company_id,
    client_id: _.get(company, 'client_id'),
    name: _.get(company, 'name'),
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
