import React from 'react'

import { Grid, IconButton } from '@mui/material'
import { Close, Check } from '@mui/icons-material'

const ButtonComponent = ({ rowId, approve, approveDisabled, refuse, refuseDisabled, ...props }) => <>
  <Grid container spacing={1}>
    <Grid item xs>
      <IconButton onClick={refuse} color={'error'} disabled={refuseDisabled}>
        <Close />
      </IconButton>
    </Grid>
    <Grid item xs>
      <IconButton onClick={approve} color={'success'} disabled={approveDisabled}>
        <Check />
      </IconButton>
    </Grid>
  </Grid>
</>

export default React.memo(ButtonComponent)
