import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box } from '@mui/material'
import { ModelMenuSelectorField } from '@front/squirtle'

import CPUtils from '@front/squirtle/utils/CPUtils'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'}> {I18n.t('authorized_package.search')} </Typography>
  </Grid>

const Row1 = props =>
  <Grid container item xs={10} justifyContent='center' spacing={2}>
    <Grid item xs>
      <ModelMenuSelectorField
        name='commercialpackage'
        label={I18n.t('commercialpackage.label', { count: -1 })}
        model_name='commercialpackage'
        config={{
          mutator: cp => ({ ...CPUtils.getTranslatedLabel(cp), ...cp })
        }}
        searchMode={'search'}
        loadOnMount
        multiple
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'commercialformula'}
        model_name='commercialformula'
        label={I18n.t('commercialformula.label', { count: -1 })}
        labelKeys={['name']}
        searchMode='search'
        loadOnMount
        multiple
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'mode'}
        model_name='ridemode'
        label={I18n.t('ride.label', { count: -1 })}
        labelKeys={['name_translated']}
        searchMode='search'
        config={{ sort: ['display_index ASC'] }}
        loadOnMount
        multiple
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Grid container justifyContent={'center'}>
    <Grid item xs={12} sm={6}>
      <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
        <Grid container rowSpacing={3} justifyContent={'center'}>
          <Title />
          <Row1 {...props} />
        </Grid>
      </Box>
    </Grid>
  </Grid>

export default React.memo(SearchForm)
