import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, Icon, StepLabel, StepContent } from '@mui/material'
import { SingleOptionSelectorField } from '@front/squirtle/'

const Title = props =>
  <Grid item xs={12}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} >
      <Icon> {'call'} </Icon>
      <Typography variant='h5'> {I18n.t('call.label', { count: 1 })}: </Typography>
    </Box>
  </Grid>

const Row1 = ({ DRIVER_CAN_CALL_CLIENT, CLIENT_CAN_CALL_DRIVER }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs hidden={!DRIVER_CAN_CALL_CLIENT}>
      <SingleOptionSelectorField name={'can_call_booker'} optionLabel={I18n.t('call.driver_client')} />
    </Grid>
    <Grid item xs hidden={!CLIENT_CAN_CALL_DRIVER}>
      <SingleOptionSelectorField name={'can_call_driver'} optionLabel={I18n.t('call.client_driver')} />
    </Grid>
  </Grid >

const Call = (props) =>
  <>
    <StepLabel>
      <Title />
    </StepLabel>
    <StepContent >
      <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
        <Grid container rowSpacing={3}>
          <Row1 {...props} />
        </Grid>
      </Box>
    </StepContent>
  </>

export default React.memo(Call)

