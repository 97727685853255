import React from 'react'

import { hooks } from '@front/volcanion'
import { Box } from '@mui/material'
import CompanyDetail from '@abra/frames/Company'

const CompanyPage = props => {
  const { company_id } = hooks.useParams()
  return (
    <Box className='safearea' sx={{ display: 'flex', justifyContent: 'center' }}>
      <CompanyDetail record_id={company_id} />
    </Box>
  )
}

export default React.memo(CompanyPage)
