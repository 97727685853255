import React, { useMemo } from 'react'

const withContainer = Component => props => {

  const translations = useMemo(() => [
    { name: 'name_trkey', labelKey: 'name.label.one', xs: 2, required: true },
    { name: 'title_trkey', labelKey: 'description.label.short', xs: 3 },
    { name: 'summary_trkey', labelKey: 'description.label.one', xs: 5 }
  ], [])

  const mergedProps = {
    translations
  }

  return <Component {...mergedProps} {...props} />
}
export default withContainer
