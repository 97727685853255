import { FormatUtils } from "@front/volcanion/utils"

class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        status: ['active', 'suspended', 'deleted'],
        ...filter
      }
    }
  }
  static filterToStateHandler(company) {
    return function filterToState(state) {
      return { company }
    }
  }
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      const { companycontract_id, status, ...rest } = values
      const formatedStatus = FormatUtils.getParseFilterStatus(status)

      return _.merge({},
        rest,
        !!companycontract_id ? {
          or: [
            { name: { startsWith: companycontract_id }, ...formatedStatus },
            { client_id: { startsWith: companycontract_id }, ...formatedStatus }
          ]
        } : {},
        !companycontract_id ? formatedStatus : {},
        { company: _.get(state, 'company') }
      )
    }
  }
}

export default Callbacks
