import React from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [isReadOnly] = hooks.useFormState('isReadOnly')

  const mergedProps = {
    isManagingRecord,
    isReadOnly
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
