import Aux from './Aux'
class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      const hasCompanyContract = !_.isEmpty(record?.companycontract_noshows)
      return {
        name: _.get(record, 'name'),
        applicable_load: _.get(record, 'applicable_load'),
        schedule: _.get(record, 'schedule'),
        start_zone: _.get(record, 'start_zone'),
        end_zone: _.get(record, 'end_zone'),
        two_way: _.get(record, 'two_way'),
        mode: _.get(_.head(_.get(record, 'companycontract_ceilings')), 'mode'),
        max_rate_customer: _.get(record, 'max_rate_customer'),
        min_rate_customer: _.get(record, 'min_rate_customer'),
        max_rate_driver: _.get(record, 'max_rate_driver'),
        min_rate_driver: _.get(record, 'min_rate_driver'),
        skip_pricerule: _.get(record, 'skip_pricerule'),
        priority: _.get(record, 'priority'),
        commercial_packages: !!hasCompanyContract
          ? _.map(record?.companycontract_ceilings, 'commercial_package')
          : _.map(record?.commercial_packages, 'commercial_package_id')
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        applicable_load: 'user',
        two_way: false
      }
    }
  }

  static getEmptyStateHandler(companycontract_id) {
    return function getEmptyState() {
      return {
        companycontract_id
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, state) {
      const hasCompanyContract = state?.companycontract_id
      return {
        name: _.get(values, 'name'),
        applicable_load: _.get(values, 'applicable_load'),
        schedule: _.get(values, 'schedule'),
        start_zone: _.get(values, 'start_zone'),
        end_zone: _.get(values, 'end_zone'),
        two_way: _.get(values, 'two_way'),
        max_rate_customer: _.get(values, 'max_rate_customer'),
        min_rate_customer: _.get(values, 'min_rate_customer'),
        max_rate_driver: _.get(values, 'max_rate_driver'),
        min_rate_driver: _.get(values, 'min_rate_driver'),
        skip_pricerule: _.get(values, 'skip_pricerule'),
        priority: _.get(values, 'priority'),
        companycontract_ceilings: !!hasCompanyContract
          ? Aux.exportCompanycontractCeilings(values, state)
          : undefined,
        commercial_packages: !hasCompanyContract
          ? values?.commercial_packages
          : undefined
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'name'),
        companycontract_id: _.get(_.head(record?.companycontract_ceilings), 'company_contract'),
        companycontract_ceilings: record?.companycontract_ceilings
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)
    }
  }

  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)
    }
  }
}

export default Callbacks
