import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Divider, Button, Icon } from '@mui/material'

const TitleComponent = ({ billing_status }) =>
  <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: 'h5.fontSize' }}>
    <Typography variant={'h5'} color={'primary'} fontWeight={300} letterSpacing={2}>
      {I18n.t('billing.status.label', { count: 1 })}
    </Typography>
    <Typography variant={'h5'} color={'primary'} fontWeight={600} letterSpacing={2} ml={1}>
      {!!billing_status && I18n.t(`billing.status.${billing_status}`)}
    </Typography>
  </Box>

const ReasonComponent = ({ reason, name }) =>
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Icon color='primary' fontSize='small' >{'circle'}</Icon>
    <Typography variant='subtitle' fontWeight={100} letterSpacing={2} color='text.input' ml={1}>
      {I18n.t(`event.${name}`)}
    </Typography>
    <Typography variant='subtitle' fontWeight={100} letterSpacing={2} color='text.input' ml={1} hidden={!reason}>
      , {reason}
    </Typography>

  </Box >

const ButtonComponent = ({ label, ...rest }) =>
  <Button
    variant={'contained'}
    size={'large'}
    sx={{ textTransform: 'none' }}
    {...rest}
  >
    <Typography variant={'h6'}>{label}</Typography>
  </Button>

const Information = ({ billing_status, billing_reasons, approve, refuse, ...props }) =>
  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>

    <ButtonComponent
      label={I18n.t('action.refuse')}
      disabled={_.includes(['valid', 'transmit', 'closed', 'to_validate'], billing_status)}
      onClick={refuse}
      color={'error'}
      startIcon={<Icon>{'close'}</Icon>}
    />

    <Box sx={{ p:2, boxShadow: 6, borderRadius: 1 }}>
      <TitleComponent billing_status={billing_status} />
      <Divider variant='middle' sx={{ my: 2 }} />
      {_.map(billing_reasons, reason => <ReasonComponent {...reason} />)}
    </Box>

    <ButtonComponent
      label={I18n.t('action.approve')}
      disabled={_.includes(['valid', 'transmit', 'closed'], billing_status)}
      onClick={approve}
      color={'success'}
      startIcon={<Icon>{'check'}</Icon>}
    />

  </Box>

export default React.memo(Information)
