import React from 'react'
import { hooks } from '@front/volcanion'

import { Box } from '@mui/material'

import CompanyContractDetails from '@abra/frames/CompanyContract'

const CompanyContractPage = props => {
  const { companycontract_id, company_id } = hooks.useParams()
  return (
    <Box className='safearea' sx={{ display: 'flex', justifyContent: 'center' }}>
      <CompanyContractDetails record_id={companycontract_id} company_id={company_id} model_name='companycontract' />
    </Box>
  )
}

export default React.memo(CompanyContractPage)
