class Callbacks {
  static onDefaultVehicleOptionsChangeHandler(defaultVehicleOptions, allowedVehicleOptions, setFormValues) {
    return function onDefaultVehicleOptionsChange() {
      const new_options = _.uniq(_.flatten([defaultVehicleOptions, allowedVehicleOptions]))
      setFormValues([{ field: 'allowed_vehicle_options', value: new_options }])
    }
  }
  static onDefaultOptionsDriverChangeHandler(defaultDriverOptions, allowedDriverOptions, setFormValues) {
    return function onDefaultOptionsDriverChange() {
      const new_options = _.uniq(_.flatten([defaultDriverOptions, allowedDriverOptions]))
      setFormValues([{ field: 'allowed_driver_options', value: new_options }])
    }
  }
}

export default Callbacks