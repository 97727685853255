
import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion/'
import Callbacks from './callbacks'

const withContainer = Component => (props) => {
  const setFormValues = hooks.useFormChange()
  const [
    defaultVehicleOptions,
    defaultDriverOptions,
    allowedVehicleOptions,
    allowedDriverOptions
  ] = hooks.useFieldValues(['default_vehicle_options', 'default_driver_options', 'allowed_vehicle_options', 'allowed_driver_options'])

  const onDefaultVehicleOptionsChange = useCallback(Callbacks.onDefaultVehicleOptionsChangeHandler(defaultVehicleOptions, allowedVehicleOptions, setFormValues), [defaultVehicleOptions, allowedVehicleOptions, setFormValues])
  const onDefaultDriverOptionsChange = useCallback(Callbacks.onDefaultOptionsDriverChangeHandler(defaultDriverOptions, allowedDriverOptions, setFormValues), [defaultDriverOptions, allowedDriverOptions, setFormValues])

  const mergedProps = {
    onDefaultVehicleOptionsChange,
    onDefaultDriverOptionsChange,
    defaultVehicleOptions,
    defaultDriverOptions
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
