import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Grid } from '@mui/material'

import Form from './Form'
import Header from './Header'
import Row from './Row'

import Callbacks from './callbacks'

const Action = props => {
  const navigate = hooks.useNavigate()
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const filterToState = useCallback(Callbacks.filterToStateHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])
  const onRowClick = useCallback((rowId, row) => navigate(`/adherent/${_.get(row, 'group.group_id')}`), [navigate])

  return (
    <Grid container justifyContent='center' alignItems='center' sx={{ bgcolor: 'background.primary' }}>
      <Grid item xs={11}>
        <Box sx={{ mt: 2 }}>
          <SearchFrame
            model_name={'action'}
            populate={['rubric', 'group', 'driver.info', 'license', 'vehicle.info', 'operator.info']}
            allowCreate={false}
          >
            <SearchForm
              filterToForm={filterToForm}
              filterToState={filterToState}
              formToFilter={formToFilter}
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable
              tableProps={{
                onRowClick
              }}
            >
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
            </SearchTable>
          </SearchFrame>
        </Box>
      </Grid>
    </Grid>
  )
}
export default React.memo(Action)
