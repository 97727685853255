
import React, { useCallback, useMemo } from 'react'
import { hooks, I18n } from '@front/volcanion'

const withContainer = Component => (props) => {
  const [delayAtSourceType] = hooks.useFieldValues(['delayAtSourceType'])
  const setFormValues = hooks.useFormChange()

  const { DURATION_DRIVER_TOO_EARLY } = hooks.useRelayConstants(['DURATION_DRIVER_TOO_EARLY'])
  const default_delay_label = _.compact([I18n.t('driver.waiting_default_delay'), _.isFinite(DURATION_DRIVER_TOO_EARLY) ? `(${DURATION_DRIVER_TOO_EARLY}min)` : `(${I18n.t('undefined')})`]).join(' ')

  const delayAtSourceOptions = useMemo(() => ([
    { label: default_delay_label, value: 'default' },
    { label: I18n.t('driver.waiting_custom_delay'), value: 'custom' },
  ]), [default_delay_label])

  const onDelayAtSourceTypeChange = useCallback((value) => {
    setFormValues([{ field: 'delay_at_source', value: value === 'custom' ? 0 : null }])
  }, [])

  const mergedProps = {
    delayAtSourceType,
    delayAtSourceOptions,
    onDelayAtSourceTypeChange
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
