
import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => (props) => {
  const [commercial_package_id] = hooks.useFieldValues(['commercialpackage'])
  const [commercial_package] = hooks.useModel('commercialpackage', [commercial_package_id], { single: true })

  const load_type = commercial_package?.load_type
  const mergedProps = {
    load_type
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
