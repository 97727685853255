import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Grid } from '@mui/material'

import Callbacks from './callbacks'

import GroupTab from '@abra/panels/Tab/Group'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const DriverList = props => {
  const { group_id } = hooks.useParams()
  const navigate = hooks.useNavigate()
  const [codeIntlPhone] = hooks.useSetting('code_international_phone')
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(codeIntlPhone, group_id), [codeIntlPhone, group_id])
  const onRowClick = useCallback((rowId, row) => navigate(`${_.get(row, 'user_id')}`), [navigate])

  return (
    <Box className='safearea'>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={11} hidden={!group_id}>
          <GroupTab value={4} />
        </Grid>
        <Grid item xs={11} >
          <SearchFrame
            model_name={'user'}
            populate={['vehicle.info', 'score', 'driverinfo', 'info', 'auth']}
            createButtonProps={{ label: I18n.t('driver.add') }}
          >
            <SearchForm
              filterToForm={filterToForm}
              formToFilter={formToFilter}
              getButtonStyleProps={() => ({ mt: 2, mb: 4 })}
              submitOnMount
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable
              tableProps={{
                onRowClick
              }}
            >
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
            </SearchTable>
          </SearchFrame>
        </Grid>
      </Grid>
    </Box >

  )
}

export default React.memo(DriverList)
