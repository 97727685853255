import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => ({ row, ...props }) => {
  const [deleteAuthorizedPOI] = hooks.useModelFunction('authorizedcommercialformulapoi', 'delete')

  const onDelete = useCallback(() => deleteAuthorizedPOI({ id: row?.id })
    , [deleteAuthorizedPOI, row?.id])

  const mergedProps = {
    row,
    onDelete
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
