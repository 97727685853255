import React from 'react'
import { Route, Routes } from '@front/squirtle'

import CommercialPackage from './Details'
import CommercialPackageList from './List'

const PricerulePage = props =>
  <Routes>
    <Route index element={<CommercialPackageList />} />
    <Route path='/create' element={<CommercialPackage />} />
    <Route path='/:commercial_package_id' element={<CommercialPackage />} />
  </Routes>

export default React.memo(PricerulePage)
