import React from 'react'
import { Route, Routes } from '@front/squirtle'

import ActionList from './List'

const ActionPage = props =>
  <Routes>
    <Route index element={<ActionList />} />
  </Routes>

export default React.memo(ActionPage)
