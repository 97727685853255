import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

import { Box, Typography } from '@mui/material'
import { ExpandPanelComponent, ExpandPanelContent, ExpandPanelTitle, TableHeader, TableRow, TableRowActions, FormContent, SearchFrame, SearchForm, SearchTable } from '@front/squirtle'


import Header from './Header'
import Row from './Row'
import ButtonRowActions from './ButtonRow'

const CustomHeader = props => {
  const count = hooks.useSearchListenerCount('order', 'order_validation_list')

  return (
    <Box sx={{ p: 1 }}>
      <Typography color={'primary'} fontSize={'2vw'} textAlign={'center'} letterSpacing={1.5}>
        {I18n.t('billing.list', { count })}
      </Typography>
    </Box>
  )
}

const BillingList = ({ handleBillingAction }) => {
  const [openSearchList, setOpenSearchList] = hooks.useGlobalState('open_search_list')
  const [, setSelectedRow] = hooks.useGlobalState('selected_validation_order')
  const [, setOpenSearchForm] = hooks.useGlobalState('open_search_form')


  const onRowClick = useCallback(rowId => {
    if (!rowId) return
    setSelectedRow(rowId)
    setOpenSearchForm(false)
    setOpenSearchList(false)
  }, [setSelectedRow, setOpenSearchList, setOpenSearchForm])


  return (
    <ExpandPanelComponent
      open={openSearchList}
      toggleOpen={setOpenSearchList}
      TransitionProps={{
        timeout: {
          appear: 2000,
          enter: 2000,
          exit: 200
        }
      }}>
      <ExpandPanelTitle><CustomHeader /></ExpandPanelTitle>
      <ExpandPanelContent>
        <SearchFrame
          formSearchName={'order_search_billing'}
          model_name={'order'}
          populate={['events']}
          sort={['createdAt ASC']}
          search_id='order_validation_list'
          allowCreate={false}
          watch_type={'index'}
          default_limit={30}
        >
          <SearchForm disableButton>
            <FormContent><></></FormContent>
          </SearchForm>
          <SearchTable
            tableProps={{
              selectRowStateID: 'selected_validation_order',
              onRowClick,
              enableRowActions: true,
              enableGlobalActions: false,
              enableToolbar: false,
              enablePagination: true,
              placementPagination: 'bottom',
              allowEditMode: false,
              allowRowGenerate: false,
              disableCancelRowLabel: false,
              disableSaveRowLabel: false,
              containerProps: {
                sx: { height: 'max-content' }
              },
              stickyHeader: true
            }}

          >
            <TableHeader><Header /></TableHeader>
            <TableRow><Row /></TableRow>
            <TableRowActions><ButtonRowActions handleBillingAction={handleBillingAction} /></TableRowActions>
          </SearchTable>
        </SearchFrame>
      </ExpandPanelContent>
    </ExpandPanelComponent >
  )
}

export default BillingList
