import React, { useCallback } from 'react'
import { I18n, hooks } from '@front/volcanion'

import { Box, Typography } from '@mui/material'

import { ExpandPanelComponent, ExpandPanelContent, ExpandPanelTitle, SearchFrame, SearchForm, FormContent } from '@front/squirtle'

import Callbacks from './callbacks'
import Form from './Search'

const Title = ({ open }) =>
  <Typography fontSize='1.8vw' color={open ? 'primary' : 'text.label'} letterSpacing={2}>
    {I18n.t('order.search')}
  </Typography>

const SearchOrderForm = props => {
  const [, setOpenList] = hooks.useGlobalState('open_list')
  const [openSearchForm, setOpenSearchForm] = hooks.useGlobalState('open_search_form')

  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(setOpenList), [setOpenList])

  return (
    <Box sx={{ boxShadow: 6, borderRadius: 1, p: 2 }}>
      <SearchFrame
        formSearchName={'order_search_billing_action'}
        model_name={'order'}
        sort={['createdAt ASC']}
        search_id='order_validation_list'
        populate={['events']}
        allowCreate={false}
        default_limit={30}
        watch_type={'index'}
      >
        <SearchForm
          filterToForm={filterToForm}
          formToFilter={formToFilter}
          getButtonStyleProps={() => ({ mt: 2 })}
          disableButton={!openSearchForm}
          submitOnMount
          submitOnEnter={false}
        >
          <FormContent>
            <ExpandPanelComponent open={openSearchForm} toggleOpen={setOpenSearchForm}>
              <ExpandPanelTitle><Title open={open} /></ExpandPanelTitle>
              <ExpandPanelContent><Form /></ExpandPanelContent>
            </ExpandPanelComponent>
          </FormContent>
        </SearchForm>
      </SearchFrame>
    </Box>
  )
}

export default React.memo(SearchOrderForm)
