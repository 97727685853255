import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid } from '@mui/material'
import { ModelAutocompleteField, DateTimeField } from '@front/squirtle'
import { UserUtils } from '@front/volcanion/utils'

const Title = ({ type }) => <Grid item xs={12}><Typography variant={'h5'}> {I18n.t(`dailyActivity.label.${type}`)} </Typography></Grid>

const Row1 = ({ type }) =>
  <Grid container item xs={11} spacing={1}>
    <Grid item xs>
      <DateTimeField
        name='createdAt_start'
        dateProps={{
          label: I18n.t('date.search'),
          inputFormat: 'DD/MM/YYYY',
        }}
        timeProps={{
          label: I18n.t('prefix.at'),
          minTime: null
        }}
        default_time='00:00'
        required
      />
    </Grid>
    <Grid item xs>
      <DateTimeField
        name='createdAt_end'
        dateProps={{
          label: I18n.t('prefix.to'),
          inputFormat: 'DD/MM/YYYY'
        }}
        timeProps={{
          label: I18n.t('prefix.at'),
          minTime: null
        }}
        default_time='23:59'
        required
      />
    </Grid>
    <Grid item xs hidden={type === 'vehicle'}>
      <ModelAutocompleteField
        name={'driver'}
        label={I18n.t('driver.search_multiple')}
        model_name={'user'}
        searchMode='search'
        searchQuery={'='}
        autocompleteMutator={q => ({
          or: [
            { info: { user_client_id: { startsWith: _.split(q, ' ') } } },
            { info: { first_name: { startsWith: _.split(q, ' ') } } },
            { info: { last_name: { startsWith: _.split(q, ' ') } } }]
        })}
        labelKeys={['label']}
        multiple={false}
        loadOnFocus={false}
        config={{
          forced_filter: { driverinfo: { '!': null } },
          populate: ['info', 'customerinfo'],
          mutator: user => ({
            label: UserUtils.getFormatedName({ ...user?.info, designation: user?.customerinfo?.name }, { prefix: user?.info?.user_client_id }),
            ...user
          })
        }}
        selectSingleOption
        debounceTime={400}
      />
    </Grid>
    <Grid item xs hidden={type === 'driver'}>
      <ModelAutocompleteField
        name={'vehicle'}
        label={I18n.t('vehicle.label', { count: 1 })}
        model_name={'vehicle'}
        searchMode='search'
        searchQuery={'startsWith'}
        autocompleteKey={'info.plaque'}
        config={{
          populate: ['info']
        }}
        labelKeys={['info.plaque']}
        debounceTime={400}
        required={type !== 'driver'}
        clearOnEmpty
        selectFirst={false}
        selectSingleOption
      />
    </Grid>
  </Grid >

const SearchForm = ({ type }) =>
  <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
    <Grid container justifyContent={'center'} rowSpacing={3}>
      <Title type={type} />
      <Row1 type={type} />
    </Grid>
  </Box>


export default React.memo(SearchForm)
