import React from 'react'
import { Route, Routes } from '@front/squirtle'

import CodePromo from './Details'
import CodePromoList from './List'

const CodePromoPage = props =>
  <Routes>
    <Route index element={<CodePromoList />} />
    <Route path='/create' element={<CodePromo />} />
    <Route path='/:codepromo_id' element={<CodePromo />} />
  </Routes>

export default React.memo(CodePromoPage)
