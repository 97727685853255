import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Grid } from '@mui/material'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import Callbacks from './callbacks'

const CommercialPackageList = props => {
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])

  return (
    <Box className={'safearea'}>
      <Grid container justifyContent='center'>
        <Grid item xs={11}>
          <SearchFrame
            model_name={'commercialpackage'}
            populate={[]}
            createButtonProps={{ label: I18n.t('commercialpackage.add') }}
          >
            <SearchForm submitOnMount formToFilter={formToFilter}>
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable>
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
            </SearchTable>
          </SearchFrame>
        </Grid>
      </Grid>
    </Box>
  )
}


export default React.memo(CommercialPackageList)
