import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid } from '@mui/material'
import { ModelAutocompleteField } from '@front/squirtle'

import UserUtils from '@front/volcanion/utils/user'

const Title = props => <Grid item xs={12}><Typography variant={'h5'}> {I18n.t('codepromo.search')}</Typography> </Grid>

const Row1 = props =>
  <Grid container item xs={8} justifyContent='center' spacing={1}>
    <Grid item xs>
      <ModelAutocompleteField
        name={'application'}
        label={I18n.t('application.label', { count: -1 })}
        model_name={'application'}
        searchMode='search'
        searchQuery={'='}
        labelKeys={['name']}
      />
    </Grid>
    <Grid item xs>
      <ModelAutocompleteField
        name={'user'}
        label={I18n.t('user.label', { count: 1 })}
        model_name={'user'}
        searchMode={'search'}
        searchQuery={'startsWith'}
        selectKeys={['name']}
        autocompleteKey={'name'}
        config={{
          mutator: user => ({
            label: UserUtils.getFormatedName({ ...user?.info, designation: user?.customerinfo?.name }),
            ...user
          }),
          populate: ['customerinfo', 'info']
        }}
        autocompleteMutator={q => ({
          or: [
            { info: { first_name: q } },
            { info: { last_name: q } },
            { customerinfo: { name: q } }
          ]
        })}
        minQueryLength={1}
        clearOnEmpty
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Grid container justifyContent={'center'}>
    <Grid item xs={12} sm={6}>
      <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
        <Grid container rowSpacing={3} justifyContent={'center'}>
          <Title />
          <Row1 {...props} />
        </Grid>
      </Box>
    </Grid>
  </Grid>

export default React.memo(SearchForm)
