import React from 'react'

import { Box, Grid } from '@mui/material'

import Detail from './Detail'
import List from './List'

import ClickAwayListener from '@mui/material/ClickAwayListener'

import SearchForm from './List/Form'
import DetailTitle from './Detail/Title'

const Validation = ({ setOpenList, handleBillingAction, openList, selectedRow }) =>
  <Box className='safearea' sx={{ ml: 4, mr: 2 }} height={1}>
    <Box sx={{ opacity: !!openList ? 0.5 : 1, mb: 8, pb: 15 }} >
      <Grid container direction='column' spacing={4}>
        <Grid item xs>
          <SearchForm />
        </Grid>
        <Grid item xs hidden={!selectedRow}>
          <DetailTitle record_id={selectedRow} handleBillingAction={handleBillingAction} />
        </Grid>
        <Grid item xs hidden={!selectedRow}>
          <Detail record_id={selectedRow} handleBillingAction={handleBillingAction} />
        </Grid>
      </Grid>
    </Box>
    <Box sx={{ position: 'fixed', display: 'flex', width: 1, bottom: 0, justifyContent: 'center' }} >
      <ClickAwayListener onClickAway={() => setOpenList(false)} mouseEvent={'onMouseUp'}>
        <Box sx={{ boxShadow: 6, borderRadius: 1, zIndex: 'modal', maxHeight: window.innerHeight - 60, overflow: 'scroll' }} >
          <List handleBillingAction={handleBillingAction} />
        </Box>
      </ClickAwayListener>
    </Box>
  </Box>


export default React.memo(Validation)
