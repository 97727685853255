class Callbacks {
  static filterToFormHandler(company_id) {
    return function filterToForm(filter) {
      return {
        company_id,
        ...filter
      }
    }
  }
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      if (_.get(values, 'contact.address'))
        _.merge(values, { contact: { address: { contains: values?.contact?.address } } })
      return values
    }
  }
}

export default Callbacks
