import { TimeUtils } from "@front/volcanion/utils"

const getDurations = (options, duration, isManagingRecord) => {
  return _.orderBy(_.flatten([
    options,
    _.compact([!!isManagingRecord && { label: TimeUtils.secondsToTime(duration, 'HH[h]mm[min]'), value: duration }])
  ]), ['value'], ['asc'])
}


const Aux = {
  getDurations
}

export default Aux
