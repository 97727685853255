import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { ModelMenuSelectorField } from '@front/squirtle/'

const Row1 = ({ record_id, load_type, ...props }) =>
  <Grid container item xs={12} spacing={2} alignItems='flex-end'>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'commercialpackage'}
        model_name='commercialpackage'
        label={I18n.t('commercialpackage.label', { count: 1 })}
        labelKeys={['name_translated']}
        searchMode='search'
        allowNone={false}
        loadOnMount
        required
        selectFirst={false}
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'commercialformula'}
        model_name='commercialformula'
        label={I18n.t('commercialformula.label', { count: 1 })}
        labelKeys={['name']}
        searchMode='search'
        allowNone={false}
        loadOnMount
        required
        selectFirst={false}
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'mode'}
        model_name='ridemode'
        label={I18n.t('ride.label', { count: 1 })}
        labelKeys={['name_translated']}
        searchMode='search'
        config={{
          forced_filter: {
            enabled: true,
            load_type: load_type
          },
          validate_filter: useCallback(() => !!load_type, [load_type]),
          watchers: [load_type],
          sort: ['display_index ASC']
        }}
        loadOnMount
        selectFirst={false}
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'channel'}
        model_name='domain'
        label={I18n.t('channel.label', { count: 1 })}
        labelKeys={['name']}
        searchMode='search'
        loadOnMount
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'source_coverage'}
        model_name='zone'
        label={I18n.t('zone.begin', { count: 1 })}
        labelKeys={['name']}
        searchMode='search'
        loadOnMount
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'destination_coverage'}
        model_name='zone'
        label={I18n.t('zone.end', { count: 1 })}
        labelKeys={['name']}
        searchMode='search'
        loadOnMount
      />
    </Grid>

  </Grid>


const PrimarySection = props => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('info.general')}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 5, boxShadow: 6, borderRadius: 3 }}>
      <Grid container rowSpacing={3}>
        <Row1 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
