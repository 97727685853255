import React from 'react'
import { hooks } from '@front/volcanion'
import { BillingUtils } from '@front/volcanion/utils'

const withContainer = Component => props => {
  const [isB2B] = hooks.useFormState('isB2B')
  const [hasJobPayment] = hooks.useFormState('hasJobPayment')
  const [transport_id] = hooks.useFormState('transport_id')

  const [driver_payment, client_payment] = hooks.useFieldValues(['job.payment', 'assigned_transport.payment'])
  const calculated_driver_total = BillingUtils.getPaymentDisplay(_.mapValues(driver_payment, _.toNumber), true, 2, false)
  const calculated_client_total = BillingUtils.getPaymentDisplay(_.mapValues(client_payment, _.toNumber), true, 2, false)

  const mergedProps = {
    calculated_driver_total,
    calculated_client_total,
    hasJobPayment,
    transport_id,
    isB2B
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
