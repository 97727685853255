import React from 'react'
import { Route, Routes } from '@front/squirtle'

import AuthorizedPackage from './Details'
import AuthorizedPackageList from './List'

const AuthorizedPackagePage = props =>
  <Routes>
    <Route index element={<AuthorizedPackageList />} />
    <Route path='/create' element={<AuthorizedPackage />} />
    <Route path='/:authorizedpackage_id' element={<AuthorizedPackage />} />
  </Routes>

export default React.memo(AuthorizedPackagePage)
