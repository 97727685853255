import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell><TextHeader label={I18n.t('driver.code')} /> </TableCell>
  <TableCell><TextHeader label={I18n.t('name.label', { count: 1 })} /> </TableCell>
  <TableCell><TextHeader label={I18n.t('phone.label', { count: 1 })} /> </TableCell>
  <TableCell><TextHeader label={I18n.t('vehicle.label', { count: 1 })} /> </TableCell>
  <TableCell><TextHeader label={I18n.t('evaluation.note', { count: 1 })} /> </TableCell>
  <TableCell><TextHeader label={I18n.t('status.label', { count: 1 })} /> </TableCell>
</>

export default React.memo(Header)
