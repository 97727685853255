import React from 'react'

import { Step, Box, Grid } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import PrimarySection from './Section/Primary'
import TranslationSection from './Section/Translation'
import Feature from './Section/Feature'
import Setting from './Section/Setting'
import Pricerule from './Section/PriceRule'
import Taxicounter from './Section/Taxicounter'
import SpecificParameter from './Section/SpecificParameter'
import Noshow from './Section/Noshow'

const CommercialPackageDetails = ({ isManagingRecord, isReadOnly }) => <>
  <Box className='safearea'>
    <Grid container justifyContent='center' rowSpacing={3}>
      <Grid item xs={11} >
        <FormHeader />
      </Grid>
      <Grid item xs={11} >
        <GridStepper groupsCount={[8]} activeStep={0} orientation="vertical">
          <Step> <PrimarySection /> </Step>
          <Step> <TranslationSection isReadOnly={isReadOnly} /> </Step>
          <Step> <Feature /> </Step>
          <Step> <Setting /> </Step>
          {!!isManagingRecord && <Step> <Pricerule /> </Step>}
          {!!isManagingRecord && <Step> <Taxicounter /></Step>}
          {!!isManagingRecord && <Step> <SpecificParameter /></Step>}
          {!!isManagingRecord && <Step> <Noshow /></Step>}
        </GridStepper>
      </Grid>
    </Grid>
  </Box>
</>

export default React.memo(CommercialPackageDetails)
