import React from 'react'
import { TableCell } from '@mui/material'

import CPUtils from '@front/squirtle/utils/CPUtils'

import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row, isSelected, ...props }) => <>
  <TableCell size={'small'}><TextCell value={_.get(CPUtils.getTranslatedLabel(row), 'label')} sx={{ textAlign: 'left' }} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'description')} sx={{ textAlign: 'left' }} /></TableCell>
</>

export default React.memo(Row)
