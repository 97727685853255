import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import { Avatar, Box, Grid, Typography } from '@mui/material'
import { ModelMenuSelectorField, MenuSelectorField } from '@front/squirtle'

const SearchForm = ({ B2B_ENABLED, count, ...props }) => <>
  <Grid container justifyContent='center'>
    <Grid item xs={11} lg={9}>
      <Box sx={{ boxShadow: 6, borderRadius: 1, p: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ color: 'primary.main', fontSize: 18, fontWeight: 600, mr: 1 }}> {I18n.t('dispatch.search', { count })} </Typography>
              <Avatar sx={{ bgcolor: 'primary.main' }}>
                <Typography variant={'rounded'} sx={{ fontWeight: 'bold' }}> {count || 0} </Typography>
              </Avatar>
            </Box>
          </Grid>
          <Grid item xs>
            <MenuSelectorField
              multiple
              name={'commercial_package.load_type'}
              label={I18n.t('transport.type')}
              options={hooks.useLoads()}
            />

          </Grid>
          <Grid item xs>
            <MenuSelectorField
              multiple
              name={'order_type'}
              label={I18n.t('order.type', { count: 1 })}
              options={_.compact([
                !!B2B_ENABLED && { label: I18n.t('subscriber.label', { count: 1 }), value: 'service' },
                { label: I18n.t('subscriber.label.not'), value: 'do' }
              ])}
            />
          </Grid>
          <Grid item xs={6}>
            <ModelMenuSelectorField
              model_name={'commercialformula'}
              name='commercial_formula'
              label={I18n.t('commercial_formula.label', { count: 1 })}
              labelKeys={['name']}
              searchMode={'search'}
              loadOnMount
              multiple
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  </Grid>
</>

export default React.memo(SearchForm)
