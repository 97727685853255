import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const B2B_ENABLED = hooks.useRelayFeature('B2B_ENABLED')

  const count = hooks.useSearchListenerCount('job', 'filtered_job_dispatch')

  const mergedProps = {
    B2B_ENABLED,
    count
  }


  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
