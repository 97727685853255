import React from 'react'
import { Route, Routes } from '@front/squirtle'

import CompanyService from './Details'

const CompanyServicePage = props =>
  <Routes>
    <Route path=':companyservice_id' element={<CompanyService />} />
    <Route path='create' element={<CompanyService />} />
  </Routes>


export default React.memo(CompanyServicePage)
