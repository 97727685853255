import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const onSubmit = hooks.useFormFunction('requestSubmit')
  const TAXI_ENABLED = hooks.useRelayFeature('TAXI_ENABLED')
  const PACKAGE_ENABLED = hooks.useRelayFeature('PACKAGE_ENABLED')
  const billing_status_list = hooks.useBillingStatusList()
  const order_status_list = hooks.useOrderStatusList()
  const transport_status_list = hooks.useTransportStatusList()
  const mergedProps = {
    TAXI_ENABLED,
    PACKAGE_ENABLED,
    billing_status_list,
    order_status_list,
    transport_status_list,
    onSubmit
  }
  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
