import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid } from '@mui/material'
import { ModelAutocompleteField, OptionSelectorField, TextField } from '@front/squirtle'

const Title = ({ client_id, name }) =>
  <Grid item xs={12}>
    <Typography variant={'h5'}> {I18n.t('companyuser.search', { client_id, name })} </Typography>
  </Grid>

const Row1 = ({ company_id }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <ModelAutocompleteField
        name={'services.contract'}
        label={I18n.t('contract.label.alter')}
        model_name={'companycontract'}
        searchMode={'search'}
        searchQuery={'contains'}
        autocompleteKey={'client_id'}
        labelKeys={['client_id']}
        multiple={false}
        config={{
          forced_filter: { company: company_id },
          validate_filter: useCallback(() => !!company_id, [company_id]),
          watchers: [company_id]
        }}
      />
    </Grid>
    <Grid item xs><TextField name='contact.last_name' label={I18n.t('name.label', { count: 1 })} /></Grid>
    <Grid item xs><TextField name='contact.first_name' label={I18n.t('name.first_name', { count: 1 })} /></Grid>
    <Grid item xs><TextField name='contact.gsm' label={I18n.t('phone.label', { count: 1 })} /></Grid>
    <Grid item xs><TextField name='contact.email' label={I18n.t('email.label', { count: 1 })} /></Grid>
    <Grid item xs={3}>
      <OptionSelectorField
        name='status'
        label={I18n.t('status.label', { count: 1 })}
        options={[
          { label: I18n.t('status.active'), color: 'green', value: 'active' },
          { label: I18n.t('status.suspended'), color: 'orange', value: 'suspended' },
          { label: I18n.t('status.deleted'), color: 'red', value: 'deleted' },
        ]}
        getOptionLabelProps={({ color }) => ({ componentsProps: { typography: { color } } })}
        multiple
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Grid container justifyContent={'center'}>
    <Grid item xs={12}>
      <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
        <Grid container rowSpacing={3} justifyContent={'center'}>
          <Title {...props} />
          <Row1 {...props} />
        </Grid >
      </Box>
    </Grid >
  </Grid >


export default React.memo(SearchForm)
