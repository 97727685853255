import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, StepLabel, StepContent, Grid, Box } from '@mui/material'

import {
  TableHeader,
  TableRow,
  TableRowActions,
  SearchFrame,
  SearchForm,
  FormContent,
  SearchTable,
  TableActions
} from '@front/squirtle'

import Callbacks from './callbacks'
import Form from './Form'
import Header from './Header'
import Row from './Row'
import ButtonRowAction from './ButtonRow'
import ButtonGlobalAction from './ButtonGlobal'

const Title = (props) =>
  <Grid item xs={12}>
    <StepLabel>
      <Typography variant='h5'>{I18n.t('commercialformula.authorized_poi.title')}</Typography>
    </StepLabel >
  </Grid >

const POISection = ({ record_id }) => {
  const formToFilter = useCallback(Callbacks.formToFilterHandler(record_id), [record_id])
  const formToRecord = useCallback(Callbacks.formToRecordHandler(record_id), [record_id])
  const getEmptyForm = useCallback(Callbacks.getEmptyFormHandler(), [])

  return <Grid container item xs={12} rowSpacing={3}>
    <Title />
    <Grid item xs={12}>
      <StepContent>
        <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
          <SearchFrame
            model_name={'authorizedcommercialformulapoi'}
            allowCreate={false}
            createPlacement={'bottom'}
          >
            <SearchForm
              formToFilter={formToFilter}
              submitOnMount
              disableButton
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable
              formProps={{
                formToRecord,
              }}
              tableProps={{
                onRowClick: null,
                enablePagination: false,
                enableRowActions: true,
                enableRowSelection: false,
                enableGlobalActions: true,
                enableGlobalActionsPlacement: 'bottom'
              }}
            >
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
              <TableRowActions><ButtonRowAction icon='delete' /></TableRowActions>
              <TableActions getEmptyForm={getEmptyForm}><ButtonGlobalAction /></TableActions>
            </SearchTable>
          </SearchFrame>
        </Box>
      </StepContent>
    </Grid>
  </Grid>
}

export default React.memo(POISection)
