import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { ModelMenuSelectorField, SingleOptionSelectorField, MenuSelectorField } from '@front/squirtle'

const Row = ({ row }) => <>
  <TableCell size={'small'}>
    <ModelMenuSelectorField
      name='poi_type'
      model_name='poitype'
      labelKeys={['name_translated']}
      searchMode={'search'}
      loadOnMount
      allowNone={false}
      config={{ force_filter: { active: true } }}
    />
  </TableCell>
  <TableCell size={'small'}><SingleOptionSelectorField name='immediate_order' /></TableCell>
  <TableCell size={'small'}><SingleOptionSelectorField name='planned_order' /></TableCell>
  <TableCell size={'small'}><SingleOptionSelectorField name='pickup_at_transport' /></TableCell>
  <TableCell size={'small'}>
    <MenuSelectorField
      name={'direction_type'}
      selectFirst
      required
      options={[
        { label: I18n.t('commercialformula.authorized_poi.from_poi.both'), value: 'all' },
        { label: I18n.t('commercialformula.authorized_poi.from_poi.from_only'), value: 'source' },
        { label: I18n.t('commercialformula.authorized_poi.from_poi.to_only'), value: 'destination' },
      ]}
    />
  </TableCell>

</>

export default React.memo(Row)
