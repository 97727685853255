import React from 'react'
import { I18n } from '@front/volcanion/'
import { OptionUtils } from '@front/volcanion/utils'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField, ModelMenuSelectorField } from '@front/squirtle/'

const Title = ({ title }) =>
  <Grid container>
    <Grid item xs>
      <Typography sx={{ fontSize: 'subtitle2.fontSize', color: 'primary.main' }}>{title}</Typography>
    </Grid>
  </Grid>

const Row1 = ({ onDefaultVehicleOptionsChange, onDefaultDriverOptionsChange }) =>
  <Grid container item xs spacing={1} rowSpacing={2} alignItems='flex-end' >
    <Grid item xs={6}>
      <ModelMenuSelectorField
        name='default_vehicle_options'
        label={I18n.t('vehicle.label.one')}
        model_name='vehicleoption'
        searchMode={'search'}
        loadOnMount
        config={{
          mutator: elem => ({ label: OptionUtils.getLabel(elem), ...elem })
        }}
        multiple
        afterChange={onDefaultVehicleOptionsChange}
      />
    </Grid>
    <Grid item xs={6}>
      <ModelMenuSelectorField
        name='default_driver_options'
        label={I18n.t('driver.label.one')}
        model_name='useroption'
        searchMode={'search'}
        loadOnMount
        config={{
          mutator: elem => ({ label: OptionUtils.getLabel(elem), ...elem })
        }}
        multiple
        afterChange={onDefaultDriverOptionsChange}
      />
    </Grid>
  </Grid>

const Row2 = ({ defaultVehicleOptions, defaultDriverOptions }) =>
  <Grid container item xs spacing={1} rowSpacing={2} alignItems='flex-end'>
    <Grid item xs={6}>
      <ModelMenuSelectorField
        name='allowed_vehicle_options'
        label={I18n.t('vehicle.label.one')}
        model_name='vehicleoption'
        searchMode={'search'}
        loadOnMount
        config={{
          mutator: elem => ({ label: OptionUtils.getLabel(elem), ...elem })
        }}
        multiple
        disabledOptions={defaultVehicleOptions}
      />
    </Grid>
    <Grid item xs={6}>
      <ModelMenuSelectorField
        name='allowed_driver_options'
        label={I18n.t('driver.label.one')}
        model_name='useroption'
        searchMode={'search'}
        loadOnMount
        config={{
          mutator: elem => ({ label: OptionUtils.getLabel(elem), ...elem })
        }}
        multiple
        disabledOptions={defaultDriverOptions}
      />
    </Grid>
  </Grid>

const Row3 = props =>
  <Grid container item xs spacing={2} rowSpacing={2} alignItems='flex-end'>
    <Grid item>
      <ModelMenuSelectorField
        name='type'
        label={I18n.t('vehicle.type')}
        model_name='vehicletype'
        labelKeys={['name_translated']}
        loadOnMount
        searchMode={'search'}
        allowNone={false}
        multiple
      />
    </Grid>
    <Grid item xs={3}>
      <TextField
        name='max_load'
        label={I18n.t('load.count')}
        type='number'
        InputProps={{
          style: { width: '40%' },
          endAdornment: <Box sx={{ fontSize: 12, color: 'text.label' }}> {I18n.t('maximum.label.short')} </Box>,
        }}
      />
    </Grid>
  </Grid>


const FeatureSection = ({ formattedSuspension, ...rest }) => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('feature.label', { count: -1 })}</Typography>
  </StepLabel>
  <StepContent>
    <Box sx={{ p: 5, boxShadow: 6, borderRadius: 3 }}>
      <Grid container rowSpacing={3} >
        <Grid item xs={12}>
          <Title title={I18n.t('options.label.default')} {...rest} />
          <Row1 {...rest} />
        </Grid>
        <Grid item xs={12}>
          <Title title={I18n.t('options.allowed')} {...rest} />
          <Row2 {...rest} />
        </Grid>
        <Grid item xs={12}>
          <Title title={I18n.t('date.booking')} {...rest} />
          <Row3 {...rest} />
        </Grid>
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(FeatureSection)
