import React, { useCallback, useMemo } from 'react'
import { I18n, hooks } from '@front/volcanion'

import { Box, TableCell, Typography, Icon } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

import FormatUtils from '@front/squirtle/utils/format'
import HookUtils from '@front/volcanion/utils/hooks'

const BILLING_EVENTS = [
  'billing_price_too_high',
  'billing_base_min',
  'billing_base_max',
  'billing_manual_dispatch'
]

const Row = ({ row, isSelected, ...props }) => {
  const parseData = useCallback(data => _.join(_.flatten(_.map(data, ({ key, value, suffix, format, ...elem }, index) => ([
    _.toLower(I18n.t(`event.keys.${key}`)), `${_.round(value, format)} ${suffix}`, index + 1 === data.length ? '' : ',']))), ' ')
    , [])

  const billing_status = _.get(row, 'billing_status')
  const billing_reasons = useMemo(() => _.chain(row?.events)
    .filter(({ name }) => _.includes(BILLING_EVENTS, name))
    .map(({ name, data }) => ({ name, reason: parseData(data) }))
    .value()
    , [HookUtils.getRecordsWatcher(row?.events), parseData, BILLING_EVENTS])

  return <>
    <TableCell size={'small'}>
      <Icon color='primary'>
        {!!_.get(row, 'service') ? 'business_center' : 'person'}
      </Icon>
    </TableCell>
    <TableCell size={'small'}>
      <TextCell value={_.get(row, 'order_client_id')} sx={{ color: 'primary.main', fontSize: 14, fontWeight: 600, whiteSpace: 'nowrap' }} />
    </TableCell>
    <TableCell size={'small'} sx={{ display: 'flex', alignItems: 'center' }}>
      <Icon color='primary' fontSize={'small'} sx={{ mr: 1 }}>
        {_.get(row, 'schedule_type') === 'planned' ? 'calendar_month' : 'download'}
      </Icon>
      <TextCell value={FormatUtils.formatDateBackToFront(_.get(row, 'requestedAt'))} />
    </TableCell>
    <TableCell size={'small'}>
      <TextCell value={!!billing_status && I18n.t(`billing.status.${billing_status}`)} sx={{ color: hooks.useBillingStatusColor(row), fontWeight: 600 }} />
    </TableCell>
    <TableCell size={'small'}>
      {_.map(billing_reasons, ({ name, reason }) => <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Icon color='primary' fontSize={'small'}>{'circle'}</Icon>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', fontSize: 13, color: 'text.label' }}>
          <Typography sx={{ fontSize: 13, ml: 1 }}>
            {I18n.t(`event.${name}`)}
          </Typography>
          <Typography sx={{ display: !!reason ? 'block' : 'none', fontSize: 13 }}>
            , {reason}
          </Typography>
        </Box>
      </Box>
      )}
    </TableCell>
  </ >
}

export default React.memo(Row)
