class Callbacks {
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      const { name, ...restFilter } = values || {}
      if (!!name)
        return values
      return restFilter
    }
  }
}

export default Callbacks
