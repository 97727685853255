

import React from 'react'
import { I18n } from '@front/volcanion'

import { ExpandPanelComponent, ExpandPanelContent, ExpandPanelTitle } from '@front/squirtle'
import { Box, Typography } from '@mui/material'

import BillingResume from './BillingResume'

import OrderFrame from '@abra/frames/Order'
import HistorySection from '@abra/frames/Order/Section/History'
import MapSection from '@abra/frames/Order/Section/Map'
import OrderHeader from '@abra/elements/headers/Order'
import FormAction from '@abra/elements/Button/Form'

const CustomHeader = ({ label, open, actionsProps, ...props }) => <Box sx={{ display: 'flex', flexDirection: 'row' }} width={1}>
  <Box sx={{ flex: 1 }}>
    <Typography fontSize='1.8vw' color={!!open ? 'primary' : 'text.label'} fontWeight={300} letterSpacing={2}>
      {label}
    </Typography>
  </Box>

  {!!actionsProps &&
    <Box sx={{ flex: 1, justifyContent: 'flex-end' }}>
      <FormAction {...actionsProps} buttonProps={{ size: 'large', variant: 'outlined' }} />
    </Box>
  }
</Box>

const NoResultComponent = ({ hidden }) =>
  <Box sx={{ borderRadius: 1, border: 2, borderColor: 'primary.main', p: 2 }} hidden={hidden}>
    <Typography variant='h3' letterSpacing={2.5} textAlign={'center'} fontWeight={200} color={'primary'}>
      {I18n.t('no_result')}
    </Typography>
  </Box>

const ValidationDetail = props => {
  const {
    record_id,
    isLoading,
    openOrder,
    openMap,
    openBilling,
    openHistory,
    setOpenOrder,
    setOpenBilling,
    setOpenHistory,
    setOpenMap
  } = props

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <NoResultComponent hidden={!isLoading} />
      <ExpandPanelComponent open={openOrder} toggleOpen={setOpenOrder} elevation={6}>
        <ExpandPanelTitle>
          <OrderHeader
            order_id={record_id}
            form_name='order_validation_form'
            headerLabelProps={{ fontSize: 'subtitle.fontSize' }}
            enableCancel={!!openOrder}
            enableSubmit={!!openOrder}
            enableGoBack={false}
            enableList={false}
            enableActions
          />
        </ExpandPanelTitle>
        <ExpandPanelContent>
          <OrderFrame
            order_id={record_id}
            form_name={'order_validation_form'}
            disableHeader
            disableHistory
            disableMap
          />
        </ExpandPanelContent>
      </ExpandPanelComponent>

      <ExpandPanelComponent open={openMap} toggleOpen={setOpenMap} elevation={6}>
        <ExpandPanelTitle>
          <CustomHeader label={I18n.t('map.label', { count: 1 })} open={openMap} />
        </ExpandPanelTitle>
        <ExpandPanelContent>
          <Box sx={{ p: 1 }}>
            <MapSection record_id={record_id} disableExpend disableTitle isMapOpen mapProps={{ boundsOnChange: true }} />
          </Box>
        </ExpandPanelContent>
      </ExpandPanelComponent>

      <ExpandPanelComponent open={openBilling} toggleOpen={setOpenBilling} elevation={6}>
        <ExpandPanelTitle>
          <CustomHeader
            label={I18n.t('billing.info', { count: 1 })}
            open={openBilling}
            actionsProps={{
              form_name: 'billing_validation_form',
              enableGoBack: false,
              enableSubmit: openBilling,
              enableCancel: openBilling
            }}
          />
        </ExpandPanelTitle>
        <ExpandPanelContent><BillingResume record_id={record_id} /></ExpandPanelContent>
      </ExpandPanelComponent>

      <ExpandPanelComponent open={openHistory} toggleOpen={setOpenHistory} elevation={6}>
        <ExpandPanelTitle>
          <CustomHeader
            label={I18n.t('history.label', { count: 1 })}
            open={openHistory}
          />
        </ExpandPanelTitle>
        <ExpandPanelContent><HistorySection record_id={record_id} load_type='user' disableTitle /></ExpandPanelContent>
      </ExpandPanelComponent>
    </Box>
  )

}

export default React.memo(ValidationDetail)
