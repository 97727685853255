import React from 'react'

import { Route, Routes } from '@front/squirtle'

import DailyActivityList from './List'

const DailyActivityPage = props =>
  <Routes>
    <Route index element={<DailyActivityList />} />
  </Routes>


export default React.memo(DailyActivityPage)
