import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell align='center'>
    <TextHeader label={I18n.t('company.label', { count: 1 })} icon={'work'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('name.label', { count: 1 })} icon={'badge'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('address.label', { count: 1 })} icon={'pin_drop'} />
  </TableCell>
</>

export default React.memo(Header)
