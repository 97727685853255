import React from 'react'

import { Divider, Grid } from '@mui/material'

import FirstRow from './Row/First'
import SecondRow from './Row/Second'
import ThirdRow from './Row/Third'

const BillingSection = props =>
  <Grid container rowSpacing={1} justifyContent={'center'}>
    <Grid item xs={12}><FirstRow/></Grid>
    <Grid item xs={12}><SecondRow/></Grid>
    <Grid item xs={12}><Divider/></Grid>
    <Grid item xs={12}><ThirdRow/></Grid>
  </Grid >


export default React.memo(BillingSection)
