import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'
import { DriverLink } from '@abra/elements'

import FormatUtils from '@front/squirtle/utils/format'

const Row = ({ row }) => {
  const createdAt = FormatUtils.formatDateBackToFront(_.get(row, 'createdAt'), 'DD/MM/YYYY HH:mm')
  const plaque =
    _.get(row, 'data.vehicle.info.plaque') || _.get(row, 'transport.vehicle.info.plaque') || _.get(row, 'vehicle.info.plaque')
  const license_number =
    _.get(row, 'data.parent_license.license_number') || _.get(row, 'transport.license.license_number') || _.get(row, 'vehicle.parent_license.license_number')
  const license_client_id =
    _.get(row, 'data.license.license_client_id') || _.get(row, 'transport.license.license_client_id') || _.get(row, 'vehicle.parent_license.license_client_id')

  return <>
    <TableCell size={'small'}><TextCell value={createdAt} /></TableCell>
    <TableCell size={'small'}><TextCell value={I18n.t(`event.${_.get(row, 'name')}`)} /></TableCell>
    <TableCell size={'small'}>
      {!!row?.driver?.user_id
        ? <DriverLink record_id={row?.driver?.user_id} />
        : '-'
      }
    </TableCell>
    <TableCell size={'small'}><TextCell value={plaque} /></TableCell>
    <TableCell size={'small'}><TextCell value={license_number} /></TableCell>
    <TableCell size={'small'}><TextCell value={license_client_id} /></TableCell>
  </>
}

export default React.memo(Row)
