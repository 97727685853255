import React, { useState, useCallback } from 'react'
import { hooks } from '@front/volcanion'
import Aux from './Aux'

const withContainer = Component => props => {
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const setFormValues = hooks.useFormChange()
  const [isReadOnly] = hooks.useFormState('isReadOnly')
  const [order_id] = hooks.useFormState('record_id')
  const [isB2B] = hooks.useFormState('isB2B')
  const [ridemode_id] = hooks.useFormState('ridemode_id')
  const [events] = useState()
  const [order] = hooks.useModel('order', [order_id], { populate: ['quotation.rate_parameter', 'specific_parameter', 'assigned_transport.info'], single: true })

  const [load_type] = hooks.useFormState('load_type')
  const [
    service,
    contract,
    included_waiting_minute,
    order_duration,
    transport_duration,
    returnAt
  ] = hooks.useFieldValues(['user.service', 'user.contract', 'included_waiting_minute', 'info.duration', 'assigned_transport.info.duration', 'returnAt'])

  const transport_at_source = _.get(_.find(events, ({ name, ...event }) => name === 'transport_at_source'), 'producedAt')
  const dispatchedAt = order?.assigned_transport?.info?.dispatchedAt

  const resetService = useCallback(() => setFormValues([
    { field: 'user.service', value: undefined },
  ]), [setFormValues])

  const handleEffectiveDurationChange = useCallback(value => {
    const calculatedBilledHandlingDuration = _.toNumber(value) - _.toNumber(included_waiting_minute)
    const handling_package = _.get(order, 'quotation.rate_parameter.handling_slice', 0) > 0
      ? Math.ceil(calculatedBilledHandlingDuration / _.get(order, 'quotation.rate_parameter.handling_slice', 0)) * _.get(order, 'quotation.rate_parameter.handling_rate', 0)
      : '0'

    const handling_user = calculatedBilledHandlingDuration * _.get(order, 'specific_parameter.waiting_minute_rate')

    setFormValues([
      { field: 'billed_handling_duration', value: calculatedBilledHandlingDuration },
      { field: 'handling', value: load_type === 'package' ? handling_package : handling_user },
      { field: 'driver_handling', value: load_type === 'package' ? handling_package : handling_user }
    ])

  }, [setFormValues, order, load_type, included_waiting_minute])

  const options = hooks.useRidemodeDurationOptions(ridemode_id)
  const order_durations = Aux.getDurations(options, order_duration, isManagingRecord)
  const transport_durations = Aux.getDurations(options, transport_duration, isManagingRecord)

  const mergedProps = {
    handleEffectiveDurationChange,
    resetService,
    returnAt,
    order_id,
    contract,
    service,
    load_type,
    dispatchedAt,
    transport_at_source,
    isReadOnly,
    isB2B,
    order_durations,
    transport_durations
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
