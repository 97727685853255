import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, StepLabel, StepContent } from '@mui/material'
import List from './List'

const TaxicounterSection = ({ disabled, record_id, ...props }) => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('taxicounter.label', { count: -1 })}</Typography>
  </StepLabel>
  {!disabled &&
    <StepContent >
      <List commercial_package_id={record_id} />
    </StepContent>
  }
</>

export default React.memo(TaxicounterSection)
