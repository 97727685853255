import React, { useCallback } from 'react'

import { Button } from '@mui/material'
import {
  TableHeader,
  TableRow,
  FormTable,
  TableRowActions,
  TableDialogAction,
  ConfirmationDialog
} from '@front/squirtle'
import { hooks, I18n } from '@front/volcanion'

import Header from './Header'
import Row from './Row'

const SpecificParameterSection = ({ commercial_package_id, record_id }) => {
  const handleCreate = useCallback(() =>
    window.open(`/noshow/create?commercial_package_id=${commercial_package_id}`, '_blank')
    [commercial_package_id])
  const handleRowClick = useCallback((rowId, row) => window.open(`/noshow/${_.get(row, 'noshow.noshow_id')}`), [])
  const [deleteNoshow] = hooks.useModelFunction('commercialpackagenoshow', 'delete')
  return (
    <>
      <Button
        color='primary'
        size='large'
        sx={{ fontSize: 'subtitle.fontSize' }}
        variant='outlined'
        onClick={handleCreate}
      >
        {I18n.t('noshow.add', { count: 1 })}
      </Button>
      <ConfirmationDialog name={'noshow_delete'} onConfirm={async (state) => deleteNoshow({ commercialpackage_noshow_id: state?.rowId })} />
      <FormTable
        model_name={'commercialpackagenoshow'}
        config={{
          forced_filter: { commercialpackage: commercial_package_id },
          validate_filter: useCallback(() => !!commercial_package_id, [commercial_package_id]),
          watchers: [commercial_package_id],
          initial_filter: {},
          populate: ['noshow', 'commercial_package']
        }}
        loadOnMount={!!record_id}
        tableProps={{
          enableRowActions: true,
          allowEditMode: false,
          allowRowGenerate: false,
          onRowClick: handleRowClick
        }}
      >
        <TableHeader><Header /></TableHeader>
        <TableRow><Row /></TableRow>

        <TableRowActions>
          <TableDialogAction
            name="noshow_delete"
            label={I18n.t('action.delete')}
            getDialogExtra={({ row }) => ({
              title: I18n.t('commercialpackage.confirm_delete_noshow', { name: _.get(row, 'noshow.name') })
            })}
          />
        </TableRowActions>
      </FormTable>
    </>
  )
}

export default React.memo(SpecificParameterSection)
