import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell align='center'>
    <TextHeader label={I18n.t('taxicounter.label', { count: 1 })} icon={'gavel'} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('zone.begin', { count: 1 })} icon={'flag'} IconProps={{ color: 'green' }} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('quotation.base')} icon={'euro_symbol'} />
  </TableCell>
</>

export default React.memo(Header)
