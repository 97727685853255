import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid } from '@mui/material'
import { TextField, ModelMenuSelectorField, OptionSelectorField } from '@front/squirtle'

const Title = ({ client_id, name, ...props }) =>
  <Grid item xs={9}>
    <Typography variant={'h5'}> {I18n.t('contract.search', { client_id, name })} </Typography>
  </Grid>

const Row1 = ({ company_id, ...props }) =>
  <Grid container item xs={12} spacing={1} justifyContent='center' alignItems='center' >
    <Grid item xs>
      <TextField
        name={'companycontract_id'}
        label={I18n.t('contract.name_client_id')}
      />
    </Grid>
    <Grid item xs>
      <TextField
        label={I18n.t('companyservice.code')}
        name='companyservices.client_id'
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name='formula'
        label={I18n.t('commercial_formula.label', { count: 1 })}
        model_name='commercialformula'
        labelKeys={['name']}
        searchMode={'search'}
        loadOnMount
        multiple
        clearOnEmpty
      />
    </Grid>
    <Grid item xs>
      <OptionSelectorField
        name={'status'}
        label={I18n.t('status.label', { count: 1 })}
        valueType={'checkbox'}
        multiple
        options={[
          { label: I18n.t('status.active'), color: 'green', value: 'active' },
          { label: I18n.t('status.inactive'), color: 'orange', value: 'suspended' },
          { label: I18n.t('status.archived'), color: 'red', value: 'deleted' },
        ]}
        getOptionLabelProps={({ color }) => ({ componentsProps: { typography: { color } } })}
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Box sx={{ p: 1, boxShadow: 6, borderRadius: 1 }}>
    <Grid container rowSpacing={3}>
      <Title {...props} />
      <Row1 {...props} />
    </Grid>
  </Box >

export default React.memo(SearchForm)
