import React from 'react'
import { I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Grid } from '@mui/material'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const CodePromo = props =>
  <Box className={'safearea'}>
    <Grid container justifyContent='center'>
      <Grid item xs={11}>
        <SearchFrame
          model_name={'codepromo'}
          allowCreate
          createButtonProps={{ label: I18n.t('codepromo.add') }}
          populate={['application', 'user.auth', 'user.customerinfo', 'user.info']}
        >
          <SearchForm submitOnMount >
            <FormContent><Form /></FormContent>
          </SearchForm>
          <SearchTable>
            <TableHeader><Header /></TableHeader>
            <TableRow><Row /></TableRow>
          </SearchTable>
        </SearchFrame>
      </Grid>
    </Grid>
  </Box>

export default React.memo(CodePromo)
