import React from 'react'
import { I18n, hooks } from '@front/volcanion'
import { useApplicationColor } from '@abra/hooks/application'

import { TableCell, Typography, Link } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

import FormatUtils from '@front/squirtle/utils/format'

const Row = ({ row }) => {
  const [{ name: user_name }] = hooks.useCustomerInfo(row?.user?.user_id)
  return <>
    <TableCell size={'small'}><TextCell value={_.get(row, 'name')} /></TableCell>
    <TableCell size={'small'}>
      {!!_.get(row, 'user') && <Typography sx={{
        color: useApplicationColor(_.get(row, 'user.auth.application')),
        fontWeight: 800,
        fontSize: 'subtitle.fontSize',
      }}
      >
        <Link
          sx={{ color: 'inherit' }}
          target='_blank'
          onClick={(e) => {
            window.open(`/client/${_.get(row, 'user.user_id')}`, '_blank')
            e.stopPropagation()
          }}
        >
          {_.compact([row?.user?.customerinfo.client_number, user_name]).join(' - ')}
        </Link>
      </Typography>}

    </TableCell>
    <TableCell size={'small'}>
      <TextCell value={_.join([
        FormatUtils.formatDateBackToFront(_.get(row, 'validityStart')),
        FormatUtils.formatDateBackToFront(_.get(row, 'validityEnd'))
      ], ' - ')} />
    </TableCell>
    <TableCell size={'small'}><TextCell value={`${_.get(row, 'amount') || '-'} ${I18n.t('fiat.eur.sign')}`} /></TableCell>
    <TableCell size={'small'}><TextCell value={I18n.t('codepromo.usage', { max_global_usage: _.get(row, 'max_global_usage'), max_user_usage: _.get(row, 'max_user_usage') })} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'application.name')} /></TableCell>
  </>
}


export default React.memo(Row)
