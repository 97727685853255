import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box } from '@mui/material'
import { TextField, ModelMenuSelectorField, DateField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'}> {I18n.t('history.search')} </Typography>
  </Grid>

const Row1 = props =>
  <Grid container item xs={10} spacing={2}>
    <Grid item xs>
      <TextField name={'operator.name'} label={I18n.t('operator', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <TextField name={'group.group_client_id'} label={I18n.t('group.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <TextField name={'license.license_client_id'} label={I18n.t('contract.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <TextField name={'driver.info.user_client_id'} label={I18n.t('driver.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <TextField name={'vehicle.info.plaque'} label={I18n.t('vehicle.label', { count: 1 })} />
    </Grid>
  </Grid>

const Row2 = props =>
  <Grid container item xs={8} spacing={2}>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'rubric'}
        model_name='rubric'
        label={I18n.t('rubric', { count: 1 })}
        labelKeys={['name_translated']}
        searchMode='search'
        loadOnMount
      />
    </Grid>
    <Grid item xs>
      <TextField name={'name'} label={I18n.t('action.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <DateField name={'createdAt_start'} label={I18n.t('date.search')} inputFormat={'DD/MM/YYYY'} />
    </Grid>
    <Grid item xs>
      <DateField name={'createdAt_end'} label={I18n.t('prefix.to')} inputFormat={'DD/MM/YYYY'} />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
    <Grid container rowSpacing={2} justifyContent='center' >
      <Title />
      <Row1 />
      <Row2 />
    </Grid>
  </Box>

export default React.memo(SearchForm)
