const exportCompanycontractCeilings = (values, state) => {
    const commercial_packages = _.get(values, 'commercial_packages')
    const mode = _.get(values, 'mode')
    const companycontract_id = _.get(state, 'companycontract_id')
    const companycontract_ceilings = _.get(state, 'companycontract_ceilings')
    const record_id = _.get(state, 'record_id')

    const exist_elems = _.map(
        _.filter(companycontract_ceilings, ccc => _.includes(commercial_packages, ccc.commercial_package)),
        elem => ({
            companycontractceiling_id: elem.companycontractceiling_id,
            ceiling: record_id,
            commercial_package: elem.commercial_package,
            company_contract: companycontract_id,
            mode,
        }))

    const new_elems = _.map(
        _.filter(commercial_packages, cp => !_.includes(_.map(companycontract_ceilings, 'commercial_package'), cp)),
        commercial_package_id => ({
            commercial_package: commercial_package_id,
            company_contract: companycontract_id,
            ceiling: record_id,
            mode,
        }))

    return _.compact(_.flatten([exist_elems, new_elems]))
}

const Aux = {
    exportCompanycontractCeilings
}

export default Aux
