import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import CPUtils from '@front/squirtle/utils/CPUtils'

import { Typography, Grid } from '@mui/material'

import { TextField, ModelMenuSelectorField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={9}>
    <Typography variant={'h5'}> {I18n.t('ceiling.search')} </Typography>
  </Grid>

const Row1 = ({ applicable_load, ...props }) =>
  <Grid container item xs={12} pacing={1}>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'commercial_packages'}
        label={I18n.t('commercialpackage.label', { count: 2 })}
        model_name='commercialpackage'
        labelKeys={['label']}
        selectFirst
        loadOnMount
        config={{
          preFilter: useCallback(cp => cp.load_type === applicable_load, [applicable_load]),
          mutator: cp => ({ ...CPUtils.getTranslatedLabel(cp), ...cp })
        }}
      />
    </Grid>
    <Grid item xs>
      <TextField name={'name'} label={I18n.t('name.label', { count: 1 })} />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Grid container rowSpacing={3}>
    <Title />
    <Row1 />
  </Grid>

export default React.memo(SearchForm)
