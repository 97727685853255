import React from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [companycontract_id] = hooks.useFormState(['companycontract_id'])
  const [companycontract] = hooks.useModel('companycontract', [companycontract_id], { single: true })
  const companyContractLabel = _.join(_.compact([companycontract?.client_id, companycontract?.name]), '-')

  const mergedProps = {
    companyContractLabel,
    companycontract_id,
    company_id: companycontract?.company,
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
