import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell><TextHeader label={I18n.t('name.label', { count: 1 })} /></TableCell>
  <TableCell><TextHeader label={I18n.t('designation')} /></TableCell>
  <TableCell><TextHeader label={I18n.t('date.validity.label')} /></TableCell>
  <TableCell><TextHeader label={I18n.t('quotation.amount')} /></TableCell>
  <TableCell><TextHeader label={I18n.t('usage.number')} /></TableCell>
  <TableCell><TextHeader label={I18n.t('application.label', { count: 1 })} /></TableCell>
</>

export default React.memo(Header)
