import React from 'react'
import { I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Grid } from '@mui/material'

import Form from './Form'
import Header from './Header'
import Row from './Row'


const AuthorizedPackage = props =>
  <Box className={'safearea'}>
    <Grid container justifyContent='center'>
      <Grid item xs={11}>
        <Box sx={{ mt: 2 }}>
          <SearchFrame
            model_name={'authorizedpackages'}
            populate={[
              'commercialpackage',
              'commercialformula',
              'channel',
              'source_coverage',
              'destination_coverage',
              'mode'
            ]}
            createButtonProps={{ label: I18n.t('authorized_package.add') }}
          >
            <SearchForm submitOnMount  >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable>
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
            </SearchTable>
          </SearchFrame>
        </Box>
      </Grid>
    </Grid>
  </Box>

export default React.memo(AuthorizedPackage)
