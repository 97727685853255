import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography } from '@mui/material'
import { TextField } from '@front/squirtle'

const ContainerComponent = ({ label, name, disabled = false }) =>
  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
    <Box sx={{ flex: 1 }}>
      <Typography color='text.label' fontSize='1vw'>   {label}: </Typography>
    </Box>
    <Box sx={{ flex: 3 }}>
      <TextField
        name={name}
        disabled={disabled}
        InputProps={{
          inputProps: {
            style: {
              padding: 0,
              textAlign: 'left',
              color: 'text.input',
              fontWeight: 200,
              fontSize: '1vw'
            }
          },
        }}
      />
    </Box>
  </Box>

const SecondRow = props =>
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <ContainerComponent name={'billing_driver_comment'} label={I18n.t('driver.comment')} disabled />
    <ContainerComponent name={'billing_group_comment'} label={I18n.t('billing.comment_group')} />
    <ContainerComponent name={'billing_customer_comment'} label={I18n.t('billing.comment_client')} />
  </Box>


export default React.memo(SecondRow)
