import React from 'react'
import { I18n } from '@front/volcanion/'

import { Grid, Typography, Box, StepLabel, StepContent } from '@mui/material'
import { TextField } from '@front/squirtle/'

const Row1 = props =>
  <Grid container justifyContent='center' spacing={1}>
    <Grid item xs>
      <TextField
        name={'min_rate_customer'}
        label={I18n.t('ceiling.min_rate_customer')}
        InputProps={{
          type: 'number',
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <Typography sx={{ color: 'input.label' }}> {I18n.t('fiat.eur.sign')}</Typography>
        }}
        required
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'max_rate_customer'}
        label={I18n.t('ceiling.max_rate_customer')}
        InputProps={{
          type: 'number',
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <Typography sx={{ color: 'input.label' }}> {I18n.t('fiat.eur.sign')}</Typography>
        }}
        required
      />
    </Grid >
    <Grid item xs>
      <TextField
        name={'min_rate_driver'}
        label={I18n.t('ceiling.min_rate_driver')}
        InputProps={{
          type: 'number',
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <Typography sx={{ color: 'input.label' }}> {I18n.t('fiat.eur.sign')}</Typography>
        }}
        required
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'max_rate_driver'}
        label={I18n.t('ceiling.max_rate_driver')}
        InputProps={{
          type: 'number',
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <Typography sx={{ color: 'input.label' }}> {I18n.t('fiat.eur.sign')}</Typography>
        }}
        required
      />
    </Grid >
  </Grid>

const CeilingSection = props => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('ceiling.label', { count: 2 })} </Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ borderRadius: 3, boxShadow: 6, p: 2, mb: 1 }}>
      <Row1 />
    </Box>
  </StepContent>
</>

export default React.memo(CeilingSection)
