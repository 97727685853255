import React, { useCallback } from 'react'
import { ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => ({ record_id }) => {
  const formProps = {
    name: 'billing_validation_form',
    model_name: 'order',
    record_id,
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    formToOptions: useCallback(Callbacks.formToOptionsHandler(), []),
    populate: [
      'do',
      'do.user_companies.companyuser_services.companyservice.contract',
      'do.info',
      'do.customerinfo',
      'do.auth',
      'commercial_package',
      'service',
      'info',
      'loads',
      'driver_estimation',
      'quotation',
      'specific_parameter',
      'driver_opts',
      'vehicle_opts',
      'specific_parameter',
      'job.payment',
      'assigned_transport.payment',
      'assigned_transport.info',
      'assigned_transport.driver',
      'transaction.codepromo.codepromo',
      'events'
    ],
    initialState: { isReadOnly: true },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), [])
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
