
import React from 'react'

import { hooks } from '@front/volcanion/'

const withContainer = Component => (props) => {
  const [
    load_type,
    to_be_grouped,
    auto_calculate_payment
  ] = hooks.useFieldValues(['load_type', 'to_be_grouped', 'auto_calculate_payment'])

  const [isReadOnly] = hooks.useFormState('isReadOnly')

  const mergedProps = {
    isPackage: load_type === 'package',
    to_be_grouped,
    auto_calculate_payment,
    isReadOnly
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
