import React from 'react'
import { hooks } from '@front/volcanion'

import { Box } from '@mui/material'

import DriverFrame from '@abra/frames/Driver'

const DriverPage = props => {
  const { user_id, group_id } = hooks.useParams()
  return (
    <Box className='safearea' sx={{ display: 'flex', justifyContent: 'center' }}>
      <DriverFrame record_id={user_id} group_id={group_id} />
    </Box>
  )
}

export default React.memo(DriverPage)
