import React from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [record_id] = hooks.useFormState('record_id')
  const { DRIVER_CAN_CALL_CLIENT, CLIENT_CAN_CALL_DRIVER } = hooks.useRelayFeatures(['DRIVER_CAN_CALL_CLIENT', 'CLIENT_CAN_CALL_DRIVER'])

  const mergedProps = {
    record_id,
    DRIVER_CAN_CALL_CLIENT,
    CLIENT_CAN_CALL_DRIVER
  }

  return <Loader isLoading={!isReady}>
    <Component {...mergedProps} {...props} />
  </Loader>
}

export default withContainer
