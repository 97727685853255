import React from 'react'
import { Route, Routes } from '@front/squirtle'

import Company from './Details'
import CompanyList from './List'
import CompanyContract from '@abra/pages/CompanyContract'
import CompanyUser from '@abra/pages/CompanyUser'

const CompanyPage = props =>
  <Routes>
    <Route index element={<CompanyList />} />
    <Route path='/create' element={<Company />} />
    <Route path='/:company_id' element={<Company />} />

    <Route path='/:company_id/contract/*' element={<CompanyContract />} />
    <Route path='/:company_id/user/*' element={<CompanyUser />} />
  </Routes>


export default React.memo(CompanyPage)
