import React from 'react'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'
import FormatUtils from '@front/squirtle/utils/format'
import { I18n } from '@front/volcanion'

const Row = ({ row }) => {
  const status = FormatUtils.getStatus(row, ['active', 'deleted', 'suspendedUntil', 'suspendedSince'])
  const statusColor = FormatUtils.getStatusColor(status)
  return <>
    <TableCell size={'small'}><TextCell value={_.get(row, 'contact.last_name')} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'contact.first_name')} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'contact.gsm')} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'contact.email')} /></TableCell>
    <TableCell size={'small'}><TextCell value={I18n.t(`status.${status}`)} sx={{ color: statusColor, fontWeight: 'bold' }} /></TableCell>
  </>
}

export default React.memo(Row)
