import _ from 'lodash'
class Callbacks {
  static onKeyDownHandler(ctrlKeyMap, baseKeyMap) {
    return function onKeyDown(e) {
      const { altKey, ctrlKey } = e
      !!ctrlKey && ctrlKeyMap(e)
      !altKey && !ctrlKey && baseKeyMap(e)
    }
  }
  static ctrlKeyMapHandler(refuse, billing_status) {
    return async function ctrlKeyMap(event) {
      const { key } = event
      if (key === 'F12') {
        event.preventDefault()
        if (_.includes(['valid', 'transmit', 'closed', 'to_validate'], billing_status))
          return
        else
          refuse()
      } else {
        return false
      }
    }
  }
  static baseKeyMapHandler(approve, billing_status) {
    return async function baseKeyMap(event) {
      const { key } = event
      if (key === 'F12') {
        event.preventDefault()
        if (_.includes(['valid', 'transmit', 'closed'], billing_status))
          return
        else
          approve()
      } else {
        return false
      }
    }
  }
}

export default Callbacks
