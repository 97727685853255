import React from 'react'
import { Route, Routes } from '@front/squirtle'

import CompanyContract from './Details'
import CompanyContractList from './List'

const CompanyContractPage = props =>
  <Routes>
    <Route index element={<CompanyContractList />} />
    <Route path=':companycontract_id' element={<CompanyContract />} />
    <Route path='create' element={<CompanyContract />} />
  </Routes>


export default React.memo(CompanyContractPage)
