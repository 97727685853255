import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Grid, Box } from '@mui/material'

import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const CompanyList = props => {

  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])

  return <Box className='safearea'>
    <Grid container spacing={1} justifyContent='center' rowSpacing={1.5} >
      <Grid item xs={12} sm={10} lg={10}>
        <SearchFrame
          model_name={'company'}
          populate={['contact']}
          createButtonProps={{
            label: I18n.t('company.add')
          }}
        >
          <SearchForm
            filterToForm={filterToForm}
            formToFilter={formToFilter}
            submitOnMount
          >
            <FormContent><Form /></FormContent>
          </SearchForm>
          <SearchTable>
            <TableHeader><Header /></TableHeader>
            <TableRow><Row /></TableRow>
          </SearchTable>
        </SearchFrame>
      </Grid>
    </Grid>
  </Box>
}

export default React.memo(CompanyList)
