import React from 'react'

import { Routes, Route } from '@front/squirtle'
import Validation from './Validation'

const BillingPage = props =>
  <Routes>
    <Route index element={<Validation />} />
    <Route path='/:order_id' element={<Validation />} />
  </Routes>

export default React.memo(BillingPage)
