import React from 'react'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

import CPUtils from '@front/squirtle/utils/CPUtils'

const Row = ({ row }) => <>
  <TableCell size={'small'}><TextCell value={_.get(CPUtils.getTranslatedLabel(_.get(row, 'commercialpackage')), 'label')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'commercialformula.name')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'channel.name')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'source_coverage.name')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'destination_coverage.name')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'mode.name_translated')} /></TableCell>
</>

export default React.memo(Row)
