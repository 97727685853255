import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell textAlign={'center'}>
    <TextHeader label={I18n.t('priority.label', { count: 1 })} />
  </TableCell>
  <TableCell textAlign={'center'}>
    <TextHeader label={I18n.t('date.label', { count: 1 })} />
  </TableCell>
  <TableCell textAlign={'center'}>
    <TextHeader label={I18n.t('type.label', { count: 1 })} />
  </TableCell>
  <TableCell textAlign={'center'}>
    <TextHeader label={I18n.t('order.id.alter')} />
  </TableCell>
  <TableCell textAlign={'center'}>
    <TextHeader label={I18n.t('commercial_formula.label', { count: 1 })} />
  </TableCell>
  <TableCell textAlign={'center'}>
    <TextHeader label={I18n.t('commercialpackage.label', { count: 1 })} />
  </TableCell>
  <TableCell textAlign={'center'}>
    <TextHeader label={I18n.t('address.label', { count: 1 })} />
  </TableCell>
  <TableCell textAlign={'center'}>
    <TextHeader label={I18n.t('channel.label', { count: 1 })} />
  </TableCell>
  <TableCell textAlign={'center'}>
    <TextHeader label={I18n.t('name.label', { count: 1 })} />
  </TableCell>
  <TableCell textAlign={'center'}>
    <TextHeader label={I18n.t('step.label', { count: -1 })} />
  </TableCell>
</>

export default React.memo(Header)
