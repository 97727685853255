import React, { useCallback, useEffect, useState } from 'react'
import { I18n, hooks } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent, MapFactory, SearchChild, Loader } from '@front/squirtle'
import { Box, Collapse } from '@mui/material'

import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'

import TabPanel from '@abra/panels/Tab/'
import { HookUtils } from '@front/volcanion/utils'

const DailyActivityList = props => {
  const [location_history_ids, search_locations, state_locations, { clear: clearLocationHistories }] = hooks.useModelSearchList('locationhistory', 'sample')
  const { openNotification } = hooks.useNotification()

  const formChange = hooks.useGlobalFormChange('event_search')
  const [type, setType] = useState(1)

  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])
  const afterSubmit = useCallback(Callbacks.afterSubmitHandler(search_locations), [search_locations])

  const onChange = useCallback(value => {
    formChange([{ field: 'driver', value: undefined }, { field: 'vehicle', value: undefined }])
    clearLocationHistories()
    setType(value)
  }, [formChange, setType, clearLocationHistories])

  useEffect(() => {
    if (state_locations.currentSize >= 1000)
      openNotification(I18n.t('dailyActivity.label.sample_limit'), { variant: 'warning' })
  }, [state_locations.currentSize])

  return (
    <Box sx={{ mx: 4, pb: 10 }}>
      <TabPanel
        onChange={onChange}
        value={type}
        options={[{
          label: I18n.t('driver.label', { count: 1 }),
          icon: 'man',
          value: 1
        }, {
          label: I18n.t('vehicle.label', { count: 1 }),
          icon: 'directions_car',
          value: 2
        }]}
      />
      <SearchFrame
        model_name='event'
        allowCreate={false}
        search_id='dailyactivity_list'
        populate={[
          'transport.driver',
          'transport.license',
          'transport.vehicle.info',
          'driver.info',
          'driver.vehicle.info',
          'vehicle.info',
          'vehicle.parent_license'
        ]}
      >
        <SearchForm
          filterToForm={filterToForm}
          formToFilter={formToFilter}
          onSubmitSuccess={afterSubmit}
          onReset={clearLocationHistories}
        >
          <FormContent><Form type={type} /></FormContent>
        </SearchForm>
        <SearchChild>
          <Loader isLoading={HookUtils.getLoadingState(state_locations)}>
            <Collapse boxShadow={3} in={!_.isEmpty(location_history_ids)}>
              <MapFactory.MapContainer mapStyle={{ height: 800 }} mapId='dailyactivity_details' fitToBounds gestureHandling>
                <MapFactory.SuperLayer fitToBounds followBounds boundOptions={{ animate: true }}>
                  <MapFactory.Polyline
                    config={{
                      model_name: 'locationhistory',
                      ids: location_history_ids,
                      coordinates_path: 'coordinates'
                    }}
                    styles={{ dashArray: "4" }}
                  />
                </MapFactory.SuperLayer>
              </MapFactory.MapContainer>
            </Collapse>
          </Loader>
        </SearchChild>
        <SearchTable tableProps={{ onRowClick: null }}>
          <TableHeader><Header /></TableHeader>
          <TableRow><Row /></TableRow>
        </SearchTable>
      </SearchFrame>
    </Box >
  )
}

export default React.memo(DailyActivityList)
