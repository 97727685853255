class Callbacks {
  static formToFilterHandler(commercial_formula) {
    return function formToFilter(values, extra, meta, state) {
      return {
        commercial_formula
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        immediate_order: false,
        planned_order: false,
        pickup_at_transport: false,
      }
    }
  }

  static formToRecordHandler(commercial_formula) {
    return function formToRecord(values, extra, meta, state) {
      return {
        commercial_formula,
        ...values
      }
    }
  }
}

export default Callbacks
