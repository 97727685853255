import React from 'react'
import { hooks } from '@front/volcanion'
import { Box } from '@mui/material'

import CompanyUserDetails from '@abra/frames/CompanyUser'

const CompanyContractPage = props => {
  const { companyuser_id, company_id } = hooks.useParams()
  return (
    <Box className='safearea' sx={{ display: 'flex', justifyContent: 'center' }}>
      <CompanyUserDetails record_id={companyuser_id} company_id={company_id} model_name='companyuser' />
    </Box>
  )
}

export default React.memo(CompanyContractPage)
