class Callbacks {
  static filterToFormHandler(PACKAGE_ENABLED, TAXI_ENABLED, B2B_ENABLED) {
    return function filterToForm(filter) {
      return {
        commercial_package: {
          load_type: _.compact([!!TAXI_ENABLED && 'user', !!PACKAGE_ENABLED && 'package'])
        },
        order_type: _.compact([B2B_ENABLED && 'service', 'do']),
        ...filter
      }
    }
  }
  static filterToStateHandler() {
    return function filterToState(state) {
      return {
      }
    }
  }
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      const { order_type, status, commercial_formula, commercial_package, ...rest } = values

      const orFilter = _.compact([
        _.includes(order_type, 'service')
          ? { service: { '!': null, contract: { formula: commercial_formula } }, commercial_package }
          : null,
        _.includes(order_type, 'do')
          ? { do: { '!': null, commercial_formula }, service: null, commercial_package, ...rest }
          : null,
        _.isEmpty(order_type)
          ? { do: { commercial_formula }, commercial_package, ...rest }
          : null,
        _.isEmpty(order_type)
          ? { service: { contract: { formula: commercial_formula } }, commercial_package }
          : null
      ])

      return {
        dispatchedAt: { '!': null },
        status: ['created', 'standby'],
        orders: {
          or: _.isEmpty(orFilter) ? undefined : orFilter
        }
      }
    }
  }
}

export default Callbacks