import React from 'react'
import { Route, Routes } from '@front/squirtle'

import Driver from './Details'
import DriverList from './List'

const DriverPage = props =>
  <Routes>
    <Route index element={<DriverList />} />
    <Route path=':user_id' element={<Driver />} />
    <Route path='create' element={<Driver />} />
  </Routes>


export default React.memo(DriverPage)
