import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell align='center'>
    <TextHeader label={I18n.t('commercialpackage.label', { count: 1 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('commercial_formula.label', { count: 1 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('channel.label', { count: 1 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('zone.begin', { count: 1 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('zone.end', { count: 1 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('ride.label', { count: 1 })} />
  </TableCell>
</>

export default React.memo(Header)
