import React from 'react'
import { Route, Routes } from '@front/squirtle'

import Ceiling from './Details'
import CeilingList from './List'

const CeilingPage = props =>
  <Routes>
    <Route index element={<CeilingList />} />
    <Route path='/create' element={<Ceiling />} />
    <Route path='/:ceiling_id' element={<Ceiling />} />
  </Routes>

export default React.memo(CeilingPage)
