import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell align='center'>
    <TextHeader label={I18n.t('commercialformula.authorized_poi.poi_type')} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('commercialformula.authorized_poi.immediate_order')} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('commercialformula.authorized_poi.planned_order')} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('commercialformula.authorized_poi.pickup_at_transport')} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('commercialformula.authorized_poi.from_poi.label', { count: 2 })} />
  </TableCell>
</>

export default React.memo(Header)