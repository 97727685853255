import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Divider } from '@mui/material'
import { TextField, TextComponent } from '@front/squirtle'

const ContainerComponent = ({
  isComponent,
  name,
  value,
  label,
  disabled = true,
  textProps = { fontWeight: 200, fontSize: '1vw', color: 'text.input' },
  ...rest
}) => {

  const sharedProps = {
    disabled,
    normalize: valueNormalize => _.max([valueNormalize, -1]),
    type: 'number',
    _adornment: {
      sx: {
        fontSize: '1vw', color: 'text.input'
      }
    },
    InputProps: {
      inputProps: {
        style: {
          padding: 0,
          textAlign: 'right',
          ...textProps
        }
      },
      disabled,
      endAdornment: I18n.t('fiat.eur.sign')
    }
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', ...rest }} {...rest}>
      <Box sx={{ flex: 1, alignItems: 'flex-end' }}>
        <Typography  {...textProps} >{`${label}:`}</Typography>
      </Box>
      <Box sx={{ flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
        {!!isComponent
          ? <TextComponent value={value} {...sharedProps} />
          : <TextField name={name} {...sharedProps} />
        }
      </Box>
    </Box>
  )
}

const Col1 = ({ disabled, calculated_driver_total }) =>
  <Box sx={{ ml: 1, flex: 1, display: 'flex', flexDirection: 'column' }}>
    <Typography fontSize='1.5vw' color='primary' fontWeight={500} letterSpacing={1.5} my={1} > {I18n.t('billing.driver')} </Typography>

    <ContainerComponent label={I18n.t('taxicounter.label.alter')} name='job.payment.base' disabled={disabled} />
    <ContainerComponent label={I18n.t('quotation.duration_amount')} name='job.payment.duration_amount' disabled={disabled} />
    <ContainerComponent label={I18n.t('quotation.duration_driver_package_fee')} name='job.payment.driver_package_fee' disabled={disabled} />
    <ContainerComponent label={I18n.t('quotation.fees')} name='job.payment.fees' disabled={disabled} />
    <ContainerComponent label={I18n.t('quotation.prepaid')} name='job.payment.prepaid' disabled={disabled} />
    <ContainerComponent label={I18n.t('wait.label', { count: 1 })} name='job.payment.handling' disabled={disabled} />
    <ContainerComponent label={I18n.t('quotation.option_amount')} name='job.payment.option_amount' disabled={disabled} />
    <Box sx={{ display: 'flex', alignItems: 'flex-end', flex: 2 }}>
      <Box sx={{ flex: 1, my: 1 }}><Divider /></Box>
    </Box>
    <ContainerComponent
      label={I18n.t('quotation.total.label.driver')}
      isComponent
      value={calculated_driver_total}
      textProps={{ fontSize: '1.3vw', fontWeight: 500 }} />
  </Box>

const Col2 = ({ disabled, calculated_client_total }) =>
  <Box sx={{ ml: 1, flex: 1, display: 'flex', flexDirection: 'column' }}>
    <Typography fontSize='1.5vw' color='primary' fontWeight={500} letterSpacing={1.5} my={1}> {I18n.t('billing.client')} </Typography>

    <ContainerComponent label={I18n.t('price.order', { count: 1 })} name='assigned_transport.payment.base' disabled={disabled} />
    <ContainerComponent label={I18n.t('quotation.duration_amount')} name='assigned_transport.payment.duration_amount' disabled={disabled} />
    <ContainerComponent label={I18n.t('quotation.duration_driver_package_fee')} name='assigned_transport.payment.driver_package_fee' disabled={disabled} />
    <ContainerComponent label={I18n.t('quotation.fees')} name='assigned_transport.payment.fees' disabled={disabled} />
    <ContainerComponent label={I18n.t('quotation.prepaid')} name='assigned_transport.payment.prepaid' disabled={disabled} />
    <ContainerComponent label={I18n.t('wait.label', { count: 1 })} name='assigned_transport.payment.handling' disabled={disabled} />
    <ContainerComponent label={I18n.t('quotation.option_amount')} name='assigned_transport.payment.option_amount' disabled={disabled} />
    <ContainerComponent label={I18n.t('discount.label', { count: 1 })} name='assigned_transport.payment.discount' disabled={disabled} />
    <ContainerComponent label={I18n.t('quotation.service_amount')} name='assigned_transport.payment.service_amount' disabled={disabled} />
    <Box sx={{ display: 'flex', alignItems: 'flex-end', flex: 2 }}>
      <Box sx={{ flex: 1, my: 1 }}><Divider /></Box>
    </Box>

    <ContainerComponent
      label={I18n.t('quotation.total.label.client')}
      isComponent value={calculated_client_total}
      textProps={{ fontSize: '1.3vw', fontWeight: 500 }}
    />

  </Box>

const SecondRow = ({ hasJobPayment, transport_id, calculated_driver_total, calculated_client_total }) =>
  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
    <Col1 disabled={!hasJobPayment} calculated_driver_total={calculated_driver_total} />
    <Col2 disabled={!transport_id} calculated_client_total={calculated_client_total} />
  </Box>


export default React.memo(SecondRow)
