import React, { useState, useCallback } from 'react'
import { hooks, I18n, CDNImage } from '@front/volcanion'

import { Box, Fade, IconButton, Button, Zoom } from '@mui/material'
import { TextComponent } from '@front/squirtle'

import { Visibility, VisibilityOff } from '@mui/icons-material/'
import QRCode from "react-qr-code";

const AuthenticationPage = (props) => {
  const { openNotification } = hooks.useNotification()
  const [username, onUsernameChange] = useState()
  const [password, onPasswordChange] = useState()
  const [display_2fa_code, setDisplay2FACode] = useState(false)
  const [_2fa_info, set2FAInfo] = useState()
  const [_2fa_code, set2FACode] = useState()

  const [displayPassword, setDisplayPassword] = useState(false)
  const [, signin] = hooks.useAuthFunction('signin')

  const handleSubmit = useCallback(async () => {
    try {
      await signin({ username, password, _2fa_secret: _2fa_info?.secret, _2fa_code })
    }
    catch (err) {
      if (_.includes(['PASSWORD_INCORRECT', 'RECORD_NOTFOUND'], err.getCode()))
        openNotification(I18n.t('login.error'), { variant: 'error' })
      else if (_.includes(['2FA_REQUIRED'], err.getCode())) {
        set2FAInfo(err.context)
        setDisplay2FACode(true)
      }
      else if (_.includes(['2FA_MISSING'], err.getCode()))
        setDisplay2FACode(true)
      else
        openNotification(I18n.t('error.unknow'), { variant: 'error' })
    }
  }, [openNotification, username, password, signin, _2fa_info, _2fa_code])

  hooks.useGlobalKeyListener(handleSubmit, ['Enter'], { disabled: !username || !password })

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(https://${hooks.useRelayConstant('CDN_HOST')}/assets/v2/fwa/images/bitmap.png)`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'bottom'
    }}>
      <Box sx={{ width: 1 / 4, display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: 6, borderRadius: 1, p: 1 }}>
        <Fade in timeout={2000}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', pl: 4, pr: 4 }}>
            <Box my={3}>
              <CDNImage name='logo' style={{ maxHeight: 30 }} />
            </Box>
            <Box my={1}>
              <TextComponent
                label={I18n.t('login.label')}
                name='username'
                type='text'
                autoFocus
                value={username}
                onChange={onUsernameChange}
                InputProps={{ style: { fontSize: 18 } }}
                InputLabelProps={{ style: { fontSize: 18 } }}
              />
            </Box>
            <Box my={1}>
              <TextComponent
                label={I18n.t('password.label', { count: 1 })}
                name='password'
                type={!!displayPassword ? 'text' : 'password'}
                value={password}
                onChange={onPasswordChange}
                InputProps={{
                  style: { fontSize: 18 },
                  endAdornment: <IconButton onClick={() => setDisplayPassword(!displayPassword)}>
                    {!!displayPassword ? <VisibilityOff color='error' /> : <Visibility color='primary' />}
                  </IconButton>
                }}
                InputLabelProps={{ style: { fontSize: 18 } }}
              />
            </Box>
            {!!_2fa_info && (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <QRCode value={_2fa_info?.uri}></QRCode>
                {_2fa_info?.secret}
              </Box>
            )}
            {!!display_2fa_code && <Box my={1}>
              <TextComponent
                label={I18n.t('2fa_code.label')}
                name='code'
                type='text'
                value={_2fa_code}
                onChange={set2FACode}
                InputProps={{ style: { fontSize: 18 } }}
                InputLabelProps={{ style: { fontSize: 18 } }}
              />
            </Box>
            }
            <Box my={2}>
              <Zoom in >
                <Box display='flex' direction='row' justifyContent='center' alignItems='center' p={2}>
                  <Button color="primary" onClick={handleSubmit} variant={'outlined'}>
                    {I18n.t('action.validate')}
                  </Button>
                </Box>
              </Zoom>
            </Box>
          </Box>
        </Fade>
      </Box >
    </Box >
  )
}

export default React.memo(AuthenticationPage)
