import React from 'react'
import { Route, Routes } from '@front/squirtle'

import CommercialFormula from './Details'
import CommercialFormulaList from './List'

const CommercialFormulaPage = props =>
  <Routes>
    <Route index element={<CommercialFormulaList />} />
    <Route path='/create' element={<CommercialFormula />} />
    <Route path='/:commercial_formula_id' element={<CommercialFormula />} />
  </Routes>

export default React.memo(CommercialFormulaPage)
