import React, { useMemo } from 'react'
import { I18n, hooks } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'
import { DriverLink } from '@abra/elements'

import FormatUtils from '@front/squirtle/utils/format'

const Row = ({ row }) => {
  const status = useMemo(() => FormatUtils.getStatus(row?.auth, ['active', 'deleted', 'suspendedUntil', 'suspendedSince']), [row?.auth])
  const statusColor = useMemo(() => FormatUtils.getStatusColor(status), [status])
  const [driver_info] = hooks.useCustomerInfo(row?.user_id)
  return <>
    <TableCell><DriverLink record_id={row?.user_id} label={row?.info?.user_client_id} /></TableCell>
    <TableCell size={'small'}> <TextCell value={driver_info?.name} /> </TableCell>
    <TableCell size={'small'}> <TextCell value={driver_info?.number} />  </TableCell>
    <TableCell size={'small'}> <TextCell value={_.get(row, 'vehicle.info.plaque')} />  </TableCell>
    <TableCell size={'small'}> <TextCell value={_.get(row, 'score.average_rating')} />  </TableCell>
    <TableCell size={'small'}> <TextCell value={I18n.t(`status.${status}`)} sx={{ color: statusColor, fontWeight: 'bold' }} />  </TableCell>
  </>
}

export default React.memo(Row)
