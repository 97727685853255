import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import { TableRow, TableHeader, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box } from '@mui/material'
import Callbacks from './callbacks'

import Header from './Header'
import Row from './Row'
import Form from './Form'

const Orders = props => {
  const PACKAGE_ENABLED = hooks.useRelayFeature('PACKAGE_ENABLED')
  const TAXI_ENABLED = hooks.useRelayFeature('TAXI_ENABLED')
  const B2B_ENABLED = hooks.useRelayFeature('B2B_ENABLED')
  const navigate = hooks.useNavigate()
  const filterToForm = useCallback(Callbacks.filterToFormHandler(PACKAGE_ENABLED, TAXI_ENABLED, B2B_ENABLED), [PACKAGE_ENABLED, TAXI_ENABLED, B2B_ENABLED])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])
  const filterToState = useCallback(Callbacks.filterToStateHandler(), [])
  const onRowClick = useCallback((rowId, row) => (_.get(row, 'orders') || []).length === 1 ? navigate(`/order/${_.get(row, 'first_step.order.order_id')}`) : navigate(`/job/${rowId}`), [navigate])

  return <Box className={'safearea'}>
    <SearchFrame
      model_name={'job'}
      populate={[
        'first_step.order.commercial_package',
        'first_step.order.do.commercial_formula',
        'first_step.order.service.contract.formula',
        'first_step.order.do.customerinfo',
        'first_step.order.do.info',
        'first_step.order.source',
        'first_step.order.destination',
        'first_step.order.driver_opts',
        'first_step.order.vehicle_opts',
        'first_step.order.channel',
        'orders'
      ]}
      allowCreate={false}
      sort={['first_step.order.do.commercial_formula.dispatch_priority DESC', 'firstDispatchedAt ASC']}
      search_id={'filtered_job_dispatch'}
      watch_type='index'
    >
      <SearchForm
        submitOnMount
        submitWatchers={[]}
        getButtonStyleProps={() => ({ mt: 2, mb: 4 })}
        filterToForm={filterToForm}
        formToFilter={formToFilter}
        filterToState={filterToState}
      >
        <FormContent><Form /></FormContent>
      </SearchForm>
      <SearchTable
        tableProps={{
          onRowClick
        }}
        formProps={{
          submitChangesOnly: false
        }}
      >
        <TableHeader><Header /></TableHeader>
        <TableRow><Row /></TableRow>
      </SearchTable>
    </SearchFrame>
  </Box>
}

export default React.memo(Orders)
