import React from 'react'
import { Route, Routes } from '@front/squirtle'

import CompanyUser from './Details'
import CompanyUserList from './List'

const CompanyUserPage = props =>
  <Routes>
    <Route index element={<CompanyUserList />} />
    <Route path=':companyuser_id' element={<CompanyUser />} />
    <Route path='create' element={<CompanyUser />} />
  </Routes>


export default React.memo(CompanyUserPage)
