import React from 'react'
import { hooks } from '@front/volcanion'
import { Box } from '@mui/material'

import CompanyServiceDetails from '@abra/frames/CompanyService'

const CompanyContractPage = props => {
  const { companyservice_id, company_id } = hooks.useParams()
  return (
    <Box className='safearea' sx={{ display: 'flex', justifyContent: 'center' }}>
      <CompanyServiceDetails record_id={companyservice_id} company_id={company_id} model_name='companyservice' />
    </Box>
  )
}

export default React.memo(CompanyContractPage)
