import React, { useEffect, useMemo, useCallback } from 'react'
import { I18n, hooks } from '@front/volcanion'

import HookUtils from '@front/volcanion/utils/hooks'

import OnKeyDown from './onKeyDown'

const BILLING_EVENTS = [
  'billing_price_too_high',
  'billing_base_min',
  'billing_base_max',
  'billing_manual_dispatch'
]

const withContainer = Component => ({ record_id, handleBillingAction }) => {
  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['events'] })

  const approve = useCallback(() => handleBillingAction('approve', record_id), [handleBillingAction, record_id])
  const refuse = useCallback(() => handleBillingAction('refuse', record_id), [handleBillingAction, record_id])
  const parseData = useCallback(data => _.join(_.flatten(_.map(data, ({ key, value, suffix, format, ...elem }, index) => ([
    _.toLower(I18n.t(`event.keys.${key}`)), `${_.round(value, format)} ${suffix}`, index + 1 === data.length ? '' : ',']))), ' ')
    , [])

  const billing_status = order?.billing_status
  const billing_reasons = useMemo(() => {
    const billing_events = _.filter(order?.events, ({ name, ...event }) => _.includes(BILLING_EVENTS, name))
    return _.map(billing_events, ({ name, data }) => ({ name, reason: parseData(data) }))
  }, [HookUtils.getRecordsWatcher(order?.events), parseData, BILLING_EVENTS])

  const ctrlKeyMap = useCallback(OnKeyDown.ctrlKeyMapHandler(refuse), [refuse, billing_status])
  const baseKeyMap = useCallback(OnKeyDown.baseKeyMapHandler(approve), [approve, billing_status])
  const onKeyDown = useCallback(OnKeyDown.onKeyDownHandler(ctrlKeyMap, baseKeyMap), [ctrlKeyMap, baseKeyMap])

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  const mergedProps = {
    refuse,
    approve,
    billing_reasons,
    billing_status,
  }

  return (

    <Component {...mergedProps} />

  )
}

export default withContainer
