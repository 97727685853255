import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const applicable_load = hooks.useFieldValue(['applicable_load'])
  const mergedProps = {
    applicable_load,
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
