import React from 'react'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box } from '@mui/material'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const CeilingList = props => <>
  <Box className='safearea'>
    <SearchFrame
      model_name={'ceiling'}
      populate={[]}
      createButtonProps={{
        label: I18n.t('ceiling.add', { count: 1 })
      }}
    >
      <SearchForm>
        <FormContent><Form /></FormContent>
      </SearchForm>
      <SearchTable>
        <TableHeader><Header /></TableHeader>
        <TableRow><Row /></TableRow>
      </SearchTable>
    </SearchFrame>
  </Box>
</>


export default React.memo(CeilingList)
