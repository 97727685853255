import React, { useCallback } from 'react'
import { I18n, hooks } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Grid } from '@mui/material'

import Callbacks from './callbacks'

import CompanyTab from '@abra/panels/Tab/Company'
import Form from './Form'
import Header from './Header'
import Row from './Row'


const CompanyList = props => {
  const navigate = hooks.useNavigate()
  const { company_id } = hooks.useParams()

  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const filterToState = useCallback(Callbacks.filterToStateHandler(company_id), [company_id])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])
  const handleCreate = useCallback(() => navigate(`/company/${company_id}/user/create`), [navigate, company_id])

  return (
    <Grid container justifyContent='center' rowSpacing={3}>
      <Grid item xs={12}>
        <CompanyTab isManagingRecord={true} value={3} />
      </Grid>
      <Grid item xs={11}>
        <SearchFrame
          model_name={'companyuser'}
          populate={[
            'user.auth',
            'user.customerinfo',
            'user.info',
            'contact'
          ]}
          sort={['contact.last_name ASC', 'contact.first_name ASC']}
          createButtonProps={{
            label: I18n.t('companyuser.add', { count: 1 }),
            onClick: handleCreate
          }}
        >
          <SearchForm
            submitOnMount={true}
            filterToForm={filterToForm}
            filterToState={filterToState}
            formToFilter={formToFilter}
          >
            <FormContent><Form company_id={company_id} /></FormContent>
          </SearchForm>
          <SearchTable >
            <TableHeader><Header /></TableHeader>
            <TableRow><Row /></TableRow>
          </SearchTable>
        </SearchFrame>
      </Grid>
    </Grid >
  )
}

export default React.memo(CompanyList)
